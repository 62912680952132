import { useContext, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/Input";
import {
  SET_MACHINE_SETUP,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  decimalRegExp,
  GROOVE_VALIDATION,
  onlyNumberRegexExp,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import TooltipContent from "../FeatureToolTipContent";
import { roundOff } from "utils";

interface IGrooveForm {
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const init_groove_data = {
  width: "",
  depth: "",
  outer_diameter: "",
  bottom_radius: "",
  quantity: 1,
  vertical_clearance: "",
  horizontal_clearance: "",
};

const GrooveForm: React.FC<IGrooveForm> = ({
  feature_id,
  setAllFieldsTouched,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        outer_diameter: Yup.number()
          .required("This field is required")
          .when("depth", (depth, schema) => {
            const minValue =
              roundOff(depth * 2) + (projectInfo?.unit === "mm" ? 0.2 : 0.0788);
            return depth
              ? schema.min(minValue, `Value must be atleast ${minValue}`)
              : schema;
          }),
        width: Yup.number()
          .required("This field is required")
          .min(
            GROOVE_VALIDATION.width.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              GROOVE_VALIDATION.width.min[projectInfo?.unit || "mm"]
            }`
          ),
        depth: Yup.number()
          .required("This field is required")
          .min(
            GROOVE_VALIDATION.depth.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              GROOVE_VALIDATION.depth.min[projectInfo?.unit || "mm"]
            }`
          ),
        bottom_radius: Yup.number()
          .required("This field is required")
          .min(
            GROOVE_VALIDATION.bottom_radius.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              GROOVE_VALIDATION.bottom_radius.min[projectInfo?.unit || "mm"]
            }`
          )
          .when("depth", (depth, schema) => {
            return depth
              ? schema.max(depth, `Value must be ${depth} or less`)
              : schema;
          }),
        quantity: QUANTITY_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
        vertical_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    const feature_info = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation?.feature_data?.feature_info,
    };

    let formValues: any = {};
    Object.keys(init_groove_data).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [setupNumber, feature_id]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_groove_data,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    if (value !== "") {
      updated_feature = {
        ...updated_feature,
        [key]: Number(value),
      };
    } else delete updated_feature[key];

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_groove_data).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Outer Diameter"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="outer_diameter"
              value={values?.outer_diameter}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("outer_diameter", values?.outer_diameter);
              }}
              infoTooltipTitle={TooltipContent("groove", "outerDiameter")}
              invalid={touched.outer_diameter && errors.outer_diameter}
              helperText={touched.outer_diameter && errors.outer_diameter}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Width"
              className="ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="width"
              value={values?.width}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("width", values?.width);
              }}
              infoTooltipTitle={TooltipContent("groove", "width")}
              invalid={touched.width && errors.width}
              helperText={touched.width && errors.width}
              regex={decimalRegExp}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Depth"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="depth"
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              infoTooltipTitle={TooltipContent("groove", "depth")}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Bottom Radius"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="bottom_radius"
              value={values?.bottom_radius}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("bottom_radius", values?.bottom_radius);
              }}
              infoTooltipTitle={TooltipContent("groove", "bottomRadius")}
              invalid={touched.bottom_radius && errors.bottom_radius}
              helperText={touched.bottom_radius && errors.bottom_radius}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="ml-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("groove", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("groove", "verticalClearance")}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("groove", "horizontalClearance")}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrooveForm;
