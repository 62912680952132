const ContourIcon = ({ active = false }) => {
  const fillClass = active ? "#D1E9FF" : "";
  const fillClass2 = active ? "#D1E9FF" : "black";
  const strokeClass = active ? "#1570EF" : "black";
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.34597 4.26096H1V4.26228C1.00278 4.26274 1.01267 4.26263 1.04083 4.26234C1.08455 4.26188 1.17234 4.26096 1.34597 4.26096Z"
        fill={strokeClass}
      />
      <path
        d="M1 11.4351L9.47843 15.0626C9.47843 15.0626 10.5835 9.83635 8.17659 7.84801C6.19101 6.20774 1 6.21753 1 6.21753V11.4351Z"
        fill={strokeClass}
      />
      <path
        d="M11.7612 10.7829L16.0004 5.89143C16.0004 5.89143 14.9763 2.86193 12.7419 1.97829C11.6434 1.54385 10.7854 1.65219 10.7854 1.65219L5.56528 4.26096C5.56528 4.26096 8.433 4.84595 9.80458 6.21753C11.1762 7.58911 11.7612 10.7829 11.7612 10.7829Z"
        fill={strokeClass}
      />
      <path
        d="M16.0004 11.4351V5.89143L11.7612 10.7829C11.7612 10.7829 11.1762 7.58911 9.80458 6.21753C8.433 4.84595 5.56528 4.26096 5.56528 4.26096H1.34597C1.17234 4.26096 1.08455 4.26188 1.04083 4.26234C1.01267 4.26263 1.00278 4.26274 1 4.26228V6.21753C1 6.21753 6.19101 6.20774 8.17659 7.84801C10.5835 9.83635 9.47843 15.0626 9.47843 15.0626H11.7636L16.0004 11.4351Z"
        fill={fillClass}
      />
      <path
        d="M1 4.26096H1.34597H5.56528L10.7854 1.65219C10.7854 1.65219 9.22673 1.00114 8.17659 1C6.68601 0.998386 5.94965 1.69456 4.58953 2.30439C3.212 2.92202 1.18923 4.12564 1 4.26096Z"
        fill={fillClass2}
        fillOpacity="0.6"
      />
      <path
        d="M1 4.26096H5.56528M1 4.26096C1.18923 4.12564 3.212 2.92202 4.58953 2.30439C5.94965 1.69456 6.68601 0.998386 8.17659 1C9.22673 1.00114 10.7854 1.65219 10.7854 1.65219M1 4.26096H1.34597M1 4.26096V4.26228M16.0004 5.89143V11.4351L11.7636 15.0626M16.0004 5.89143L11.7612 10.7829C11.7612 10.7829 11.1762 7.58911 9.80458 6.21753C8.433 4.84595 5.56528 4.26096 5.56528 4.26096M16.0004 5.89143C16.0004 5.89143 14.9763 2.86193 12.7419 1.97829C11.6434 1.54385 10.7854 1.65219 10.7854 1.65219M1 11.4351L9.47843 15.0626M1 11.4351V6.21753M1 11.4351V4.26228M9.47843 15.0626H11.7636M9.47843 15.0626C9.47843 15.0626 10.5835 9.83635 8.17659 7.84801C6.19101 6.20774 1 6.21753 1 6.21753M11.7636 15.0626V10.7829M5.56528 4.26096L10.7854 1.65219M5.56528 4.26096H1.34597M1 6.21753V4.26228M1.34597 4.26096C1.17234 4.26096 1.08455 4.26188 1.04083 4.26234C1.01267 4.26263 1.00278 4.26274 1 4.26228"
        stroke={strokeClass}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default ContourIcon;
