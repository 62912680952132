import { useFormik } from "formik";
import { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import Input from "components/Input";
import {
  BOTTOM_FACE_WALL_TYPE_OPTIONS,
  SET_MACHINE_SETUP,
  BOTTOM_FACE_VALIDATION,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  decimalRegExp,
  onlyNumberRegexExp,
  WALL_ANGLE_VALIDATION,
} from "constant";
import Select from "components/Select";
import TooltipContent from "../../FeatureToolTipContent";
import { roundOff } from "utils";

interface IBottomFace {
  feature_info?: any;
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const init_bottom_face = {
  length: "",
  width: "",
  wall_height: "",
  quantity: "",
  floor_radius: "",
  wall_type: "",
  wall_angle: "",
  vertical_clearance: "",
  horizontal_clearance: "",
};

const BottomFace: React.FC<IBottomFace> = ({
  feature_info,
  feature_id,
  setAllFieldsTouched,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { setupNumber, projectInfo, machiningSetups } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number()
          .required("This field is required")
          .min(
            BOTTOM_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              BOTTOM_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"]
            }`
          ),
        width: Yup.number()
          .required("This field is required")
          .min(
            BOTTOM_FACE_VALIDATION.width.min[projectInfo?.unit || "mm"],
            `Value must be atleast ${
              BOTTOM_FACE_VALIDATION.width.min[projectInfo?.unit || "mm"]
            }`
          ),
        wall_height: Yup.number()
          .required("This field is required")
          .min(
            BOTTOM_FACE_VALIDATION.wall_height.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              BOTTOM_FACE_VALIDATION.wall_height.min[projectInfo?.unit || "mm"]
            }`
          ),
        wall_angle: WALL_ANGLE_VALIDATION,
        wall_type: Yup.string()
          .oneOf([
            ...BOTTOM_FACE_WALL_TYPE_OPTIONS.map((option: any) => option.value),
          ])
          .required("This field is required"),
        floor_radius: Yup.number()
          .required("This field is required")
          .min(0, "Value must be atleast 0")
          .when("wall_height", (wall_height, schema) => {
            if (!wall_height) return schema;
            const maxValue = roundOff(wall_height / 2);
            return schema.max(maxValue, `Value must be ${maxValue} or less`);
          }),
        quantity: QUANTITY_VALIDATION,
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_bottom_face).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_bottom_face,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    if (key === "wall_type") {
      updated_feature = {
        ...updated_feature,
        [key]: value,
      };
      if (value === "flat" || value === "curved") {
        delete updated_feature.wall_angle;
        setFieldValue("wall_angle", "");
      } else if (value === "no walls") {
        delete updated_feature.wall_angle;
        setFieldValue("wall_angle", "");
        delete updated_feature.wall_height;
        setFieldValue("wall_height", "");
        delete updated_feature.floor_radius;
        setFieldValue("floor_radius", "");
      }
    } else if (value !== "") {
      updated_feature = {
        ...updated_feature,
        [key]: Number(value),
      };
    } else delete updated_feature[key];

    if (flag) setFieldValue(key, value);
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_bottom_face).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div>
      <div className="mt-4">
        <p className="font-bold text-base text-gray-202">Feature Dimensions</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("bottom_face", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Width"
              className="ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="width"
              value={values?.width}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("width", values?.width);
              }}
              infoTooltipTitle={TooltipContent("bottom_face", "width")}
              invalid={touched.width && errors.width}
              helperText={touched.width && errors.width}
              regex={decimalRegExp}
            />
          </div>
        </div>
        {["tapered", "curved", "flat"].includes(values?.wall_type) && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Wall Height"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="wall_height"
                value={values?.wall_height}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("wall_height", values?.wall_height);
                }}
                infoTooltipTitle={TooltipContent("bottom_face", "wallHeight")}
                invalid={touched.wall_height && errors.wall_height}
                helperText={touched.wall_height && errors.wall_height}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="font-bold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="pr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("bottom_face", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
          <div className="flex w-6/12">
            <Select
              className="w-full ml-2"
              options={BOTTOM_FACE_WALL_TYPE_OPTIONS}
              placeholder="Wall Type"
              value={values?.wall_type}
              onChange={(val: any) => {
                handleChangeFunc("wall_type", val, true);
              }}
              handleClose={(value: string) => {
                if (!value) setFieldTouched("wall_type", true);
              }}
              infoTooltipTitle={TooltipContent("bottom_face", "wallType")}
              onBlur={handleBlur}
              invalid={touched.wall_type && errors.wall_type}
              helperText={touched.wall_type && errors.wall_type}
            />
          </div>
        </div>
        {["tapered", "curved", "flat"].includes(values?.wall_type) && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Floor Radius"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="floor_radius"
                value={values?.floor_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("floor_radius", values?.floor_radius);
                }}
                infoTooltipTitle={TooltipContent("bottom_face", "floorRadius")}
                invalid={touched.floor_radius && errors.floor_radius}
                helperText={touched.floor_radius && errors.floor_radius}
                regex={decimalRegExp}
              />
            </div>
            {values?.wall_type === "tapered" && (
              <div className="w-6/12">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Wall Angle"
                  className="ml-2"
                  type="text"
                  name="wall_angle"
                  value={values?.wall_angle}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("wall_angle", values?.wall_angle);
                  }}
                  infoTooltipTitle={TooltipContent("bottom_face", "wallAngle")}
                  invalid={touched.wall_angle && errors.wall_angle}
                  helperText={touched.wall_angle && errors.wall_angle}
                  regex={decimalRegExp}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "bottom_face",
                "verticalClearance"
              )}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full ml-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "bottom_face",
                "horizontalClearance"
              )}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomFace;
