import Button from "components/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Dispatch, SetStateAction } from "react";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";

interface IConfirmFeatureSubTypeSwitchModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleContinue: Function;
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  width: "100%",
  borderRadius: "16px",
  outline: "none",
};

const ConfirmFeatureSubTypeSwitchModal: React.FC<
  IConfirmFeatureSubTypeSwitchModal
> = ({ isOpen, setIsOpen, handleContinue }) => {
  return (
    <div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={modalStyle}>
          <div className="flex flex-col items-center justify-between pt-[16px] pb-[16px] gap-2">
            <img src={WarningSign} alt="" />
          </div>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "60vh",
            }}
          >
            <div className="mt-0">
              <div className="text-[14px] text-center text-gray-700">
                Switching the feature will remove existing feature input values.
                <br></br>
                Are you sure you want to proceed?
              </div>
            </div>
          </Box>
          <div className="flex justify-center w-full p-[16px] flex-row space-x-4">
            <Button
              size="md"
              className="px-[16px] py-[8px] text-[14px] font-semibold font-inter normal-case rounded-[4px] w-[150px] text-center bg-gray-f2f text-gray-344 hover:opacity-80 active:opacity-50 "
              onClick={() => setIsOpen(false)}
            >
              No
            </Button>
            <Button
              onClick={handleContinue}
              className="px-[16px] py-[8px] text-[14px] font-semibold font-inter normal-case rounded-[4px] w-[150px] text-center  bg-surface-default text-white hover:opacity-80 active:opacity-50"
            >
              Yes, continue
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmFeatureSubTypeSwitchModal;
