import TooltipV2 from "components/ToolTipV2";
import { getHumanDate } from "utils";

interface StyledProjectValueProps {
  project: any;
  valueKey: string;
}

const StyledProjectValue: React.FC<StyledProjectValueProps> = ({
  project,
  valueKey,
}) => {
  return (
    <div>
      {" "}
      {/* Added these */}
      {(() => {
        switch (valueKey) {
          case "projectName":
            return (
              <>
                {project?.[valueKey]?.length > 20 ? (
                  <TooltipV2 title={project?.[valueKey]}>
                    {project?.[valueKey].slice(0, 19) + "..."}
                  </TooltipV2>
                ) : (
                  project?.[valueKey]
                )}
              </>
            );
          case "createdAt":
            if (!project?.hasOwnProperty(valueKey)) return <>-</>;
            return <>{getHumanDate(new Date(project?.createdAt))}</>;
          default:
            if (project?.hasOwnProperty(valueKey)) {
              return <>{project?.[valueKey]}</>;
            }
            return <>-</>;
        }
      })()}
    </div>
  );
};

export default StyledProjectValue;
