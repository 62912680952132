import {
  BOTTOM_ANGLE_VALIDATION,
  BOTTOM_FACE_VALIDATION,
  BOTTOM_FACE_WALL_TYPE_OPTIONS,
  CHAMFER_HOLE_TYPE_OPTIONS,
  CHAMFER_TYPE_OPTIONS,
  CHAMFER_VALIDATION,
  FILLET_BOTTOM_TYPE_OPTIONS,
  FILLET_LOCATION_OPTIONS,
  FILLET_ORIENTATION_OPTIONS,
  FILLET_SHAPE_OPTIONS,
  FILLET_VALIDATION,
  FINISH_TYPE_OPTIONS,
  GROOVE_VALIDATION,
  HOLE_BOTTOM_OPTIONS,
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_SURFACE_TYPE_OPTIONS,
  HOLE_TYPE_OPTIONS,
  HOLE_VALIDATION,
  ISLAND_INFO_VALIDATION,
  POCKET_FLOOR_TYPE_OPTIONS,
  POCKET_TYPE_OPTIONS,
  POCKET_VALIDATION,
  POCKET_WALL_TYPE_OPTIONS,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  SIDE_FACE_BOTTOM_TYPE_OPTIONS,
  SIDE_FACE_SURFACE_TYPE_OPTIONS,
  SIDE_FACE_VALIDATION,
  SLOT_LOCATION_OPTIONS,
  SLOT_OPEN_SIDES_OPTIONS,
  SLOT_SLOT_TYPE_OPTIONS,
  SLOT_TYPE_OPTIONS,
  SLOT_VALIDATION,
  TAPER_ANGLE_VALIDATION,
  THREAD_ANGLE_VALIDATION,
  THREAD_HOLE_VALIDATION,
  TOP_FACE_FINISHING_SURFACE_OPTIONS,
  TOP_FACE_VALIDATION,
  WALL_ANGLE_VALIDATION,
} from "constant";
import { roundOff } from "utils";
import * as Yup from "yup";

export function simpleHoleFeatureValidation(unit: string) {
  return Yup.object().shape({
    diameter: Yup.number()
      .required("This field is required")
      .min(
        HOLE_VALIDATION.diameter.min[unit || "mm"],
        `Value must be at least ${HOLE_VALIDATION.diameter.min[unit || "mm"]}`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        HOLE_VALIDATION.depth.min[unit || "mm"],
        `Value must be at least ${HOLE_VALIDATION.depth.min[unit || "mm"]}`
      ),
    bottom_type: Yup.string()
      .oneOf([...HOLE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    bottom: Yup.string().when("bottom_type", {
      is: "blind",
      then: Yup.string()
        .oneOf([...HOLE_BOTTOM_OPTIONS.map((elem) => elem.value)])
        .required("This field is required"),
      otherwise: Yup.string().strip(),
    }),
    bottom_radius: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "flat",
      then: Yup.number()
        .required("This field is required")
        .min(0, "Minimum value must be 0")
        .when("diameter", (diameter, schema) => {
          return diameter
            ? schema.max(
                roundOff(diameter / 2),
                `Value must be ${roundOff(diameter / 2)} or less`
              )
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    bottom_angle: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "v shape",
      then: BOTTOM_ANGLE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    surface_type: Yup.string()
      .oneOf([...HOLE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    deburring: Yup.string()
      .oneOf(["yes", "no"])
      .required("This field is required"),
    sequential: Yup.string()
      .oneOf(["yes", "no"])
      .required("This field is required"),
    quantity: QUANTITY_VALIDATION,
    hole_type: Yup.string()
      .oneOf([...HOLE_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    stock_to_remove: Yup.number().when("hole_type", {
      is: "hollow",
      then: Yup.number()
        .required("This field is required")
        .moreThan(0, "Value must be greater than 0"),
      otherwise: Yup.number().strip(),
    }),
    finish_type: Yup.string().when("$isFinishTypeCustom", {
      is: true,
      then: Yup.string().strip(),
      otherwise: Yup.string()
        .oneOf([
          ...FINISH_TYPE_OPTIONS.map((option: any) => option.value).filter(
            (option) => option !== "custom"
          ),
        ])
        .required("This field is required"),
    }),
    upper_tolerance: Yup.number().when("$isFinishTypeCustom", {
      is: false,
      then: Yup.number().strip(),
      otherwise: Yup.number()
        .moreThan(0, "Value must be greater than 0")
        .required("This field is required"),
    }),
    lower_tolerance: Yup.number().when("$isFinishTypeCustom", {
      is: false,
      then: Yup.number().strip(),
      otherwise: Yup.number()
        .required("This field is required")
        .typeError("Please enter a valid number")
        .when("upper_tolerance", (upper_tolerance, schema) => {
          return upper_tolerance
            ? schema.lessThan(
                upper_tolerance,
                `Value must be less than ${upper_tolerance}`
              )
            : schema;
        }),
    }),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function threadHoleFeatureValidation(unit: string) {
  return Yup.object().shape({
    thread_size: Yup.number()
      .required("This field is required")
      .moreThan(0, "Value must be greater than 0"),
    minor_diameter: Yup.number()
      .required("This field is required")
      .moreThan(0, "Value must be greater than 0"),
    thread_pitch: Yup.number()
      .required("This field is required")
      .moreThan(0, "Value must be greater than 0")
      .max(
        THREAD_HOLE_VALIDATION.thread_pitch.max[unit || "mm"],
        `Value must be ${
          THREAD_HOLE_VALIDATION.thread_pitch.max[unit || "mm"]
        } or less`
      ),
    thread_depth: Yup.number()
      .required("This field is required")
      .min(
        THREAD_HOLE_VALIDATION.thread_depth.min[unit || "mm"],
        `Value must be atleast ${
          THREAD_HOLE_VALIDATION.thread_depth.min[unit || "mm"]
        }`
      ),
    depth: Yup.number()
      .required("This field is required")
      .when("thread_depth", (thread_depth, schema) => {
        return thread_depth
          ? schema.min(thread_depth, `Value must be atleast ${thread_depth}`)
          : schema;
      }),
    thread_angle: THREAD_ANGLE_VALIDATION,
    bottom_type: Yup.string()
      .oneOf([...HOLE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    bottom: Yup.string().when("bottom_type", {
      is: "blind",
      then: Yup.string()
        .oneOf([...HOLE_BOTTOM_OPTIONS.map((elem) => elem.value)])
        .required("This field is required"),
      otherwise: Yup.string().strip(),
    }),
    bottom_radius: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "flat",
      then: Yup.number()
        .when("thread_size", (minor_diameter, schema) => {
          return minor_diameter
            ? schema.max(
                roundOff(minor_diameter / 2),
                `Value must be ${roundOff(minor_diameter / 2)} or less`
              )
            : schema;
        })
        .required(),
      otherwise: Yup.number().strip(),
    }),
    bottom_angle: Yup.number().when(["bottom_type", "bottom"], {
      is: (bottom_type: string, bottom: string) =>
        bottom_type === "blind" && bottom === "v shape",
      then: BOTTOM_ANGLE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    deburring: Yup.string()
      .oneOf(["yes", "no"])
      .required("This field is required"),
    quantity: QUANTITY_VALIDATION,
    hole_type: Yup.string()
      .oneOf([...HOLE_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    stock_to_remove: Yup.number().when("hole_type", {
      is: "hollow",
      then: Yup.number()
        .required("This field is required")
        .moreThan(0, "Value must be greater than 0"),
      otherwise: Yup.number().strip(),
    }),
    sequential: Yup.string()
      .oneOf(["yes", "no"])
      .required("This field is required"),
    surface_type: Yup.string()
      .oneOf([...HOLE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    finish_type: Yup.string().when("$isFinishTypeCustom", {
      is: true,
      then: Yup.string().strip(),
      otherwise: Yup.string()
        .oneOf([
          ...FINISH_TYPE_OPTIONS.map((option: any) => option.value).filter(
            (option) => option !== "custom"
          ),
        ])
        .required("This field is required"),
    }),
    upper_tolerance: Yup.number().when("$isFinishTypeCustom", {
      is: false,
      then: Yup.number().strip(),
      otherwise: Yup.number()
        .moreThan(0, "Value must be greater than 0")
        .required("This field is required"),
    }),
    lower_tolerance: Yup.number().when("$isFinishTypeCustom", {
      is: false,
      then: Yup.number().strip(),
      otherwise: Yup.number()
        .required("This field is required")
        .typeError("Please enter a valid number")
        .when("upper_tolerance", (upper_tolerance, schema) => {
          return upper_tolerance
            ? schema.lessThan(
                upper_tolerance,
                `Value must be less than ${upper_tolerance}`
              )
            : schema;
        }),
    }),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function topFaceFeatureValidation(unit: string) {
  return Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        TOP_FACE_VALIDATION.length.min[unit || "mm"],
        `Value must be at least ${TOP_FACE_VALIDATION.length.min[unit || "mm"]}`
      ),
    width: Yup.number()
      .required("This field is required")
      .min(
        TOP_FACE_VALIDATION.width.min[unit || "mm"],
        `Value must be at least ${TOP_FACE_VALIDATION.width.min[unit || "mm"]}`
      ),
    stock_to_remove: Yup.number()
      .required("This field is required")
      .moreThan(0, "Value must be greater than 0"),
    quantity: QUANTITY_VALIDATION,
    finishing_surface: Yup.string()
      .oneOf([...TOP_FACE_FINISHING_SURFACE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function bottomFaceFeatureValidation(unit: string) {
  return Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        BOTTOM_FACE_VALIDATION.length.min[unit || "mm"],
        `Value must be at least ${
          BOTTOM_FACE_VALIDATION.length.min[unit || "mm"]
        }`
      ),
    width: Yup.number()
      .required("This field is required")
      .min(
        BOTTOM_FACE_VALIDATION.width.min[unit || "mm"],
        `Value must be atleast ${
          BOTTOM_FACE_VALIDATION.width.min[unit || "mm"]
        }`
      ),
    wall_height: Yup.number().when("wall_type", {
      is: (wall_type: string) =>
        ["tapered", "curved", "flat"].includes(wall_type),
      then: Yup.number()
        .required("This field is required")
        .min(
          BOTTOM_FACE_VALIDATION.wall_height.min[unit || "mm"],
          `Value must be at least ${
            BOTTOM_FACE_VALIDATION.wall_height.min[unit || "mm"]
          }`
        ),
      otherwise: Yup.number().strip(),
    }),
    quantity: QUANTITY_VALIDATION,
    floor_radius: Yup.number().when("wall_type", {
      is: (wall_type: string) =>
        ["tapered", "curved", "flat"].includes(wall_type),
      then: Yup.number()
        .required("This field is required")
        .min(0, "Value must be atleast 0")
        .when("wall_height", (wall_height, schema) => {
          if (!wall_height) return schema;
          const maxValue = roundOff(wall_height / 2);
          return schema.max(maxValue, `Value must be ${maxValue} or less`);
        }),
      otherwise: Yup.number().strip(),
    }),
    wall_type: Yup.string()
      .oneOf([...BOTTOM_FACE_WALL_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    wall_angle: Yup.number().when("wall_type", {
      is: (wall_type: string) => wall_type === "tapered",
      then: WALL_ANGLE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function sideFaceFeatureValidation(unit: string) {
  return Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        SIDE_FACE_VALIDATION.length.min[unit || "mm"],
        `Value must be at least ${
          SIDE_FACE_VALIDATION.length.min[unit || "mm"]
        }`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        SIDE_FACE_VALIDATION.depth.min[unit || "mm"],
        `Value must be at least ${SIDE_FACE_VALIDATION.depth.min[unit || "mm"]}`
      ),
    stock_to_remove: Yup.number()
      .required("This field is required")
      .min(0, "Value must be atleast 0"),
    bottom_type: Yup.string()
      .oneOf([...SIDE_FACE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    bottom_radius: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: POSITIVE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    bottom_width: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: Yup.number()
        .required("This field is required")
        .moreThan(0, "Value must be greater than 0"),
      otherwise: Yup.number().strip(),
    }),
    quantity: QUANTITY_VALIDATION,
    surface_type: Yup.string()
      .oneOf([...SIDE_FACE_SURFACE_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    taper_angle: Yup.number().when("surface_type", {
      is: (surface_type: string) => surface_type === "tapered",
      then: TAPER_ANGLE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    taper_length: Yup.number().when("surface_type", {
      is: (surface_type: string) => surface_type === "tapered",
      then: Yup.number().required("This field is required"),
      otherwise: Yup.number().strip(),
    }),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function pocketFeatureValidation(unit: string) {
  return Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.length.min[unit || "mm"],
        `Value must be atleast ${POCKET_VALIDATION.length.min[unit || "mm"]}`
      ),
    width: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.width.min[unit || "mm"],
        `Value must be atleast ${POCKET_VALIDATION.width.min[unit || "mm"]}`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.depth.min[unit || "mm"],
        `Value must be at least ${POCKET_VALIDATION.depth.min[unit || "mm"]}`
      ),
    quantity: QUANTITY_VALIDATION,
    pocket_type: Yup.string()
      .oneOf([...POCKET_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    min_distance: Yup.number().when("pocket_type", {
      is: (pocket_type: string) => pocket_type === "closed",
      then: Yup.number()
        .required("This field is required")
        .min(
          POCKET_VALIDATION.min_distance.min[unit || "mm"],
          `Value must be at least ${
            POCKET_VALIDATION.min_distance.min[unit || "mm"]
          }`
        )
        .test("is-less-than-eqaul-to-min", function (value: any) {
          const { width = 0, length = 0 } = this.parent;
          if (!width && !length) {
            return true;
          }
          const min = Math.min(width, length);

          if (min <= POCKET_VALIDATION.width.min[unit || "mm"]) return true;
          return (
            value <= min ||
            this.createError({
              path: this.path,
              message: `Value must be ${min} or less`,
            })
          );
        }),
      otherwise: Yup.number().strip(),
    }),
    no_of_walls: Yup.number().when("pocket_type", {
      is: (pocket_type: string) => pocket_type === "open",
      then: Yup.number().oneOf([2, 3]).required("This field is required"),
      otherwise: Yup.number().strip(),
    }),
    corner_radius: Yup.number().when(["pocket_type", "$isCornerRadius"], {
      is: (pocket_type: string, isCornerRadius: boolean) => {
        return (
          pocket_type === "closed" || (pocket_type === "open" && isCornerRadius)
        );
      },
      then: Yup.number()
        .required("This field is required")
        .min(
          POCKET_VALIDATION.corner_radius.min[unit || "mm"],
          `Value must be at least ${
            POCKET_VALIDATION.corner_radius.min[unit || "mm"]
          }`
        )
        .when("min_distance", (min_distance, schema) => {
          return min_distance >=
            POCKET_VALIDATION.min_distance.min[unit || "mm"]
            ? schema.max(min_distance, `Value must be ${min_distance} or less`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    wall_type: Yup.string()
      .oneOf([...POCKET_WALL_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    taper_angle: Yup.number().when("wall_type", {
      is: (wall_type: string) => wall_type === "tapered",
      then: TAPER_ANGLE_VALIDATION,
      otherwise: Yup.number().strip(),
    }),
    floor_radius: Yup.number().when("floor_type", {
      is: (floor_type: string) => floor_type === "solid",
      then: Yup.number()
        .required("This field is required")
        .min(
          POCKET_VALIDATION.floor_radius.min[unit || "mm"],
          `Value must be at least ${
            POCKET_VALIDATION.floor_radius.min[unit || "mm"]
          }`
        )
        .when("depth", (depth, schema) => {
          return depth
            ? schema.max(depth, `Value must be ${depth} or less`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    floor_type: Yup.string()
      .oneOf([...POCKET_FLOOR_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    island_info: Yup.object().when("floor_type", {
      is: (floor_type: string) => floor_type === "solid",
      then: Yup.object()
        .shape({
          islands: Yup.array().of(
            Yup.object().shape({
              length: Yup.number().test(
                "less-than-length",
                function (value: any, context: any) {
                  if (value === undefined) {
                    return this.createError({
                      path: this.path,
                      message: `This field is required`,
                    });
                  }
                  const length = Number(context.from?.[2]?.value?.length ?? 0);
                  if (length <= POCKET_VALIDATION.length.min[unit || "mm"])
                    return true;
                  if (value < ISLAND_INFO_VALIDATION.length.min[unit || "mm"])
                    return this.createError({
                      path: this.path,
                      message: `Value must be at least ${
                        ISLAND_INFO_VALIDATION.length.min[unit || "mm"]
                      }`,
                    });
                  return (
                    value < length - 0.2 * (unit === "mm" ? 1 : 0.0394) ||
                    this.createError({
                      path: this.path,
                      message: `Value must be less than ${
                        length - 0.2 * (unit === "mm" ? 1 : 0.0394)
                      }`,
                    })
                  );
                }
              ),
              width: Yup.number().test(
                "less-than-width",
                function (value: any, context: any) {
                  if (value === undefined) {
                    return this.createError({
                      path: this.path,
                      message: `This field is required`,
                    });
                  }
                  const width = Number(context.from?.[2]?.value?.width ?? 0);
                  if (width <= POCKET_VALIDATION.width.min[unit || "mm"])
                    return true;
                  if (value < ISLAND_INFO_VALIDATION.width.min[unit || "mm"])
                    return this.createError({
                      path: this.path,
                      message: `Value must be at least ${
                        ISLAND_INFO_VALIDATION.width.min[unit || "mm"]
                      }`,
                    });
                  return (
                    value < width - 0.2 * (unit === "mm" ? 1 : 0.0394) ||
                    this.createError({
                      path: this.path,
                      message: `Value must be less than ${
                        width - 0.2 * (unit === "mm" ? 1 : 0.0394)
                      }`,
                    })
                  );
                }
              ),
              height: Yup.number()
                .required("This field is required")
                .min(
                  ISLAND_INFO_VALIDATION.height.min[unit || "mm"],
                  `Value must be at least ${
                    ISLAND_INFO_VALIDATION.height.min[unit || "mm"]
                  }`
                ),
              floor_radius: Yup.number()
                .required("This field is required")
                .min(
                  ISLAND_INFO_VALIDATION.floor_radius.min[unit || "mm"],
                  `Value must be at least ${
                    ISLAND_INFO_VALIDATION.floor_radius.min[unit || "mm"]
                  }`
                )
                .when("height", (height, schema) => {
                  return height
                    ? schema.max(height, `Value must be ${height} or less`)
                    : schema;
                }),
              min_x_distance_to_face_edges: Yup.number().test(function (
                value: any,
                context: any
              ) {
                if (value === undefined) {
                  return this.createError({
                    path: this.path,
                    message: `This field is required`,
                  });
                }
                const length = Number(context.from?.[2]?.value?.length ?? 0);
                const island_length = Number(context.parent?.length ?? 0);
                if (
                  length <= POCKET_VALIDATION.length.min[unit || "mm"] ||
                  island_length <
                    ISLAND_INFO_VALIDATION.length.min[unit || "mm"]
                ) {
                  return true;
                }
                if (
                  value <
                  ISLAND_INFO_VALIDATION.min_x_distance_to_face_edges.min[
                    unit || "mm"
                  ]
                ) {
                  return this.createError({
                    path: this.path,
                    message: `Value must be at least ${
                      ISLAND_INFO_VALIDATION.min_x_distance_to_face_edges.min[
                        unit || "mm"
                      ]
                    }`,
                  });
                }
                return (
                  value < roundOff((length - island_length) / 2) ||
                  this.createError({
                    path: this.path,
                    message: `Value must be less than ${roundOff(
                      (length - island_length) / 2
                    )}`,
                  })
                );
              }),
              min_y_distance_to_face_edges: Yup.number().test(function (
                value: any,
                context: any
              ) {
                if (value === undefined) {
                  return this.createError({
                    path: this.path,
                    message: `This field is required`,
                  });
                }
                const width = Number(context.from?.[2]?.value?.width ?? 0);
                const island_width = Number(context.parent?.width ?? 0);
                if (
                  width <= POCKET_VALIDATION.width.min[unit || "mm"] ||
                  island_width < ISLAND_INFO_VALIDATION.width.min[unit || "mm"]
                ) {
                  return true;
                }
                if (
                  value <
                  ISLAND_INFO_VALIDATION.min_y_distance_to_face_edges.min[
                    unit || "mm"
                  ]
                ) {
                  return this.createError({
                    path: this.path,
                    message: `Value must be at least ${
                      ISLAND_INFO_VALIDATION.min_y_distance_to_face_edges.min[
                        unit || "mm"
                      ]
                    }`,
                  });
                }
                return (
                  value < roundOff((width - island_width) / 2) ||
                  this.createError({
                    path: this.path,
                    message: `Value must be less than ${roundOff(
                      (width - island_width) / 2
                    )}`,
                  })
                );
              }),
            })
          ),
          min_distance_between_islands: Yup.number().when("islands", {
            is: (islands: any) => islands?.length > 1,
            then: Yup.number()
              .min(
                POCKET_VALIDATION.min_distance_between_islands.min[
                  unit || "mm"
                ],
                `Value must be at least ${
                  POCKET_VALIDATION.min_distance_between_islands.min[
                    unit || "mm"
                  ]
                }`
              )
              .required("This field is required"),
            otherwise: Yup.number().strip(),
          }),
        })
        .default(undefined),
      otherwise: Yup.object().strip(),
    }),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });
}

export function slotFeatureValidation(unit: string) {
  return Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        SLOT_VALIDATION.length.min[unit || "mm"],
        `Value must be at least ${SLOT_VALIDATION.length.min[unit || "mm"]}`
      ),
    width: Yup.number()
      .required("This field is required")
      .min(
        SLOT_VALIDATION.width.min[unit || "mm"],
        `Value must be at least ${SLOT_VALIDATION.width.min[unit || "mm"]}`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        SLOT_VALIDATION.depth.min[unit || "mm"],
        `Value must be at least ${SLOT_VALIDATION.depth.min[unit || "mm"]}`
      ),
    slot_type: Yup.string()
      .oneOf([...SLOT_SLOT_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    type: Yup.string().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.string().oneOf(["open"]).required(),
      otherwise: Yup.string()
        .oneOf([...SLOT_TYPE_OPTIONS.map((elem) => elem.value)])
        .required("This field is required"),
    }),
    open_sides: Yup.string().test(
      "open-sides-validation",
      function (value: any, context: any) {
        const { slot_type, type } = context.parent;
        if (slot_type === "t-slot" || slot_type === "dovetail") {
          if (value === "both") return true;
          else {
            return this.createError({
              message: "This field is required and must be 'both'",
            });
          }
        } else if (slot_type === "simple" && type === "open") {
          const validValues = SLOT_OPEN_SIDES_OPTIONS.map((elem) => elem.value);
          if (!validValues.includes(value)) {
            return this.createError({
              message: "This field is required",
            });
          }
        }
        return true;
      }
    ),
    section_depth: Yup.number().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.number()
        .required("This field is required")
        .min(
          SLOT_VALIDATION.section_depth.min[unit || "mm"],
          `Value must be at least ${
            SLOT_VALIDATION.section_depth.min[unit || "mm"]
          }`
        ),
      otherwise: Yup.number().strip(),
    }),
    section_width: Yup.number().when("slot_type", {
      is: (slot_type: string) =>
        slot_type === "t-slot" || slot_type === "dovetail",
      then: Yup.number()
        .required("This field is required")
        .when("width", (width, schema) => {
          return width
            ? schema.max(width + 0.1, `Value must be atleast ${width + 0.1}`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    location: Yup.string().when(["slot_type", "open_sides"], {
      is: (slot_type: string, open_sides: string) =>
        slot_type === "simple" && open_sides === "both",
      then: Yup.string()
        .oneOf([...SLOT_LOCATION_OPTIONS.map((elem) => elem.value)])
        .required("This field is required"),
      otherwise: Yup.string().strip(),
    }),
    corner_radius: Yup.number().when(["type", "open_sides"], {
      is: (type: string, open_sides: string) => {
        return type === "closed" || open_sides === "one";
      },
      then: Yup.number()
        .required("This field is required")
        .min(
          SLOT_VALIDATION.corner_radius.min[unit || "mm"],
          `Value must be at least ${
            SLOT_VALIDATION.corner_radius.min[unit || "mm"]
          }`
        )
        .when("width", (width, schema) => {
          return width
            ? schema.max(
                roundOff(width / 2),
                `Value must be ${roundOff(width / 2)} or less`
              )
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    bottom_type: Yup.string()
      .oneOf([...SIDE_FACE_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    bottom_radius: Yup.number().when("bottom_type", {
      is: (bottom_type: string) => bottom_type === "solid",
      then: Yup.number()
        .required("This field is required")
        .min(
          SLOT_VALIDATION.bottom_radius.min[unit || "mm"],
          `Value must be at least ${
            SLOT_VALIDATION.bottom_radius.min[unit || "mm"]
          }`
        )
        .when("depth", (depth, schema) => {
          return depth
            ? schema.max(depth, `Value must be ${depth} or less`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    quantity: QUANTITY_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
    vertical_clearance: POSITIVE_VALIDATION,
  });
}

export function filletFeatureValidation(unit: string) {
  return Yup.object().shape({
    location: Yup.string()
      .oneOf([...FILLET_LOCATION_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    orientation: Yup.string()
      .when("location", {
        is: (location: string) => location === "hole top",
        then: Yup.string().oneOf(["horizontal"]),
        otherwise: Yup.string().oneOf([
          ...FILLET_ORIENTATION_OPTIONS.map((elem) => elem.value),
        ]),
      })
      .required("This field is required"),
    shape: Yup.string()
      .when("location", {
        is: (location: string) => location === "hole top",
        then: Yup.string().oneOf(["convex"]),
        otherwise: Yup.string().oneOf([
          ...FILLET_SHAPE_OPTIONS.map((elem) => elem.value),
        ]),
      })
      .required("This field is required"),
    bottom_type: Yup.string().when(["location", "orientation", "shape"], {
      is: (location: string, orientation: string, shape: string) =>
        location === "edge" &&
        orientation === "horizontal" &&
        shape === "convex",
      then: Yup.string()
        .oneOf([...FILLET_BOTTOM_TYPE_OPTIONS.map((elem) => elem.value)])
        .required("This field is required"),
      otherwise: Yup.string().strip(),
    }),
    length: Yup.number().when("location", {
      is: (location: string) => location === "edge",
      then: Yup.number()
        .required("This field is required")
        .min(
          FILLET_VALIDATION.length.min[unit || "mm"],
          `Value must be at least ${FILLET_VALIDATION.length.min[unit || "mm"]}`
        ),
      otherwise: Yup.number().strip(),
    }),
    hole_diameter: Yup.number().when("location", {
      is: (location: string) => location === "hole top",
      then: Yup.number()
        .required("This field is required")
        .when("radius", (radius, schema) => {
          const minValue =
            roundOff(radius / 2) + (unit === "mm" ? 0.2 : 0.0788);
          return radius
            ? schema.min(minValue, `Value must be atleast ${minValue}`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    radius: Yup.number()
      .required("This field is required")
      .min(
        FILLET_VALIDATION.radius.min[unit || "mm"],
        `Value must be at least ${FILLET_VALIDATION.radius.min[unit || "mm"]}`
      ),
    quantity: QUANTITY_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
    vertical_clearance: POSITIVE_VALIDATION,
  });
}

export function grooveFeaureValidation(unit: string) {
  return Yup.object().shape({
    outer_diameter: Yup.number()
      .required("This field is required")
      .when("depth", (depth, schema) => {
        const minValue = roundOff(depth * 2) + (unit === "mm" ? 0.2 : 0.0788);
        return depth
          ? schema.min(minValue, `Value must be atleast ${minValue}`)
          : schema;
      }),
    width: Yup.number()
      .required("This field is required")
      .min(
        GROOVE_VALIDATION.width.min[unit || "mm"],
        `Value must be at least ${GROOVE_VALIDATION.width.min[unit || "mm"]}`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        GROOVE_VALIDATION.depth.min[unit || "mm"],
        `Value must be at least ${GROOVE_VALIDATION.depth.min[unit || "mm"]}`
      ),
    bottom_radius: Yup.number()
      .required("This field is required")
      .min(
        GROOVE_VALIDATION.bottom_radius.min[unit || "mm"],
        `Value must be at least ${
          GROOVE_VALIDATION.bottom_radius.min[unit || "mm"]
        }`
      )
      .when("depth", (depth, schema) => {
        return depth
          ? schema.max(depth, `Value must be ${depth} or less`)
          : schema;
      }),
    quantity: QUANTITY_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
    vertical_clearance: POSITIVE_VALIDATION,
  });
}

export function chamferFeatureValidation(unit: string) {
  return Yup.object().shape({
    type: Yup.string()
      .oneOf([...CHAMFER_TYPE_OPTIONS.map((elem) => elem.value)])
      .required("This field is required"),
    edge_length: Yup.number().when("type", {
      is: (type: string) => type === "edge chamfer",
      then: Yup.number()
        .required("This field is required")
        .min(
          CHAMFER_VALIDATION.edge_length.min[unit || "mm"],
          `Value must be at least ${
            CHAMFER_VALIDATION.edge_length.min[unit || "mm"]
          }`
        ),
      otherwise: Yup.number().strip(),
    }),
    hole_diameter: Yup.number().when("type", {
      is: (type: string) => type === "hole chamfer",
      then: Yup.number()
        .required("This field is required")
        .when("length", (length, schema) => {
          const minValue =
            roundOff(length * 2) + (unit === "mm" ? 0.2 : 0.0788);
          return length
            ? schema.min(minValue, `Value must be atleast ${minValue}`)
            : schema;
        }),
      otherwise: Yup.number().strip(),
    }),
    hole_type: Yup.string().when("type", {
      is: "hole chamfer",
      then: Yup.string()
        .oneOf([
          ...CHAMFER_HOLE_TYPE_OPTIONS.map((option: any) => option.value),
        ])
        .required("This field is required"),
      otherwise: Yup.string().strip(),
    }),
    hole_depth: Yup.number().when("type", {
      is: "hole chamfer",
      then: Yup.number()
        .required("This field is required")
        .min(
          CHAMFER_VALIDATION.hole_depth.min[unit || "mm"],
          `Value must be at least ${
            CHAMFER_VALIDATION.hole_depth.min[unit || "mm"]
          }`
        ),
      otherwise: Yup.number().strip(),
    }),
    length: Yup.number()
      .required("This field is required")
      .moreThan(0, "Value must be greater than 0"),
    angle: Yup.number()
      .required("This field is required")
      .min(0.0001, "Value must be greater than 0")
      .max(89.9999, "Value must be less than 90"),
    quantity: QUANTITY_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
    vertical_clearance: POSITIVE_VALIDATION,
  });
}
