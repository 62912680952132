import TooltipV2 from "components/ToolTipV2";
import { notifyCAM } from "utils";

interface ICamFeatureHightlightButton {
  tagId: string;
  label: string;
  tooltipContainer: string;
}

const CamFeatureHightlightButton: React.FC<ICamFeatureHightlightButton> = ({
  tagId,
  label,
  tooltipContainer,
}) => {
  return (
    <TooltipV2
      title="Click to Preview"
      slotProps={{
        popper: {
          disablePortal: false,
          container: document.getElementById(tooltipContainer),
        },
      }}
    >
      <button
        onClick={() => {
          notifyCAM("highlight-feature", {
            tag_id: tagId,
          });
        }}
        className="text-blue-600 underline cursor-pointer"
      >
        {label}
      </button>
    </TooltipV2>
  );
};

export default CamFeatureHightlightButton;
