import { useContext, useEffect } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  POCKET_FLOOR_TYPE_OPTIONS,
  CONFIRM_OPTIONS,
  POCKET_TYPE_OPTIONS,
  POCKET_WALL_TYPE_OPTIONS,
  SET_MACHINE_SETUP,
  POCKET_VALIDATION,
  ISLAND_INFO_VALIDATION,
  QUANTITY_VALIDATION,
  POSITIVE_VALIDATION,
  decimalRegExp,
  POCKET_NO_OF_WALLS_OPTIONS,
  onlyNumberRegexExp,
  TAPER_ANGLE_VALIDATION,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipContent from "../../FeatureToolTipContent";
import TooltipV2 from "components/ToolTipV2";
import { roundOff } from "utils";

const init_2d_pocket = {
  length: "",
  width: "",
  depth: "",
  pocket_type: "",
  use_corner_radius: "",
  corner_radius: "",
  min_distance: "",
  wall_type: "",
  taper_angle: "",
  floor_type: "",
  floor_radius: "",
  islands: [],
  min_distance_between_islands: "",
  quantity: "",
  island_count: 0,
  no_of_walls: "",
  vertical_clearance: "",
  horizontal_clearance: "",
};

const TwoDForm = ({ feature_id, feature_info, setAllFieldsTouched }: any) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups } = state;
  // const [isLandCount, setIsLandCount] = useState(0);

  const islandSchema = Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.length.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.length.min[projectInfo?.unit || "mm"]
        }`
      )
      .test("less-than-length", function (value: any) {
        const length = Number(values?.length ?? 0);
        if (length <= POCKET_VALIDATION.length.min[projectInfo?.unit || "mm"])
          return true;
        return (
          value <
            roundOff(
              length - 0.2 * (projectInfo?.unit === "mm" ? 1 : 0.0394)
            ) ||
          this.createError({
            path: this.path,
            message: `Value must be less than ${roundOff(
              length - 0.2 * (projectInfo?.unit === "mm" ? 1 : 0.0394)
            )}`,
          })
        );
      }),
    width: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.width.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.width.min[projectInfo?.unit || "mm"]
        }`
      )
      .test("less-than-width", function (value: any) {
        const width = Number(values?.width ?? 0);
        if (width <= POCKET_VALIDATION.width.min[projectInfo?.unit || "mm"])
          return true;
        return (
          value <
            roundOff(width - 0.2 * (projectInfo?.unit === "mm" ? 1 : 0.0394)) ||
          this.createError({
            path: this.path,
            message: `Value must be less than ${roundOff(
              width - 0.2 * (projectInfo?.unit === "mm" ? 1 : 0.0394)
            )}`,
          })
        );
      }),
    height: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.height.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.height.min[projectInfo?.unit || "mm"]
        }`
      ),
    floor_radius: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.floor_radius.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.floor_radius.min[projectInfo?.unit || "mm"]
        }`
      )
      .when("height", (height, schema) => {
        return height
          ? schema.max(height, `Value must be ${height} or less`)
          : schema;
      }),
    min_x_distance_to_face_edges: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.min_x_distance_to_face_edges.min[
          projectInfo?.unit || "mm"
        ],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.min_x_distance_to_face_edges.min[
            projectInfo?.unit || "mm"
          ]
        }`
      )
      .test(function (value: any) {
        const length = Number(values?.length ?? 0);
        const island_length = Number(this.parent.length ?? 0);
        if (
          length <= POCKET_VALIDATION.length.min[projectInfo?.unit || "mm"] ||
          island_length <
            ISLAND_INFO_VALIDATION.length.min[projectInfo?.unit || "mm"]
        ) {
          return true;
        }
        return (
          value < roundOff((length - island_length) / 2) ||
          this.createError({
            path: this.path,
            message: `Value must be less than ${roundOff(
              (length - island_length) / 2
            )}`,
          })
        );
      }),
    min_y_distance_to_face_edges: Yup.number()
      .required("This field is required")
      .min(
        ISLAND_INFO_VALIDATION.min_y_distance_to_face_edges.min[
          projectInfo?.unit || "mm"
        ],
        `Value must be at least ${
          ISLAND_INFO_VALIDATION.min_y_distance_to_face_edges.min[
            projectInfo?.unit || "mm"
          ]
        }`
      )
      .test(function (value: any) {
        const width = Number(values?.width ?? 0);
        const island_width = Number(this.parent.width ?? 0);
        if (
          width <= POCKET_VALIDATION.width.min[projectInfo?.unit || "mm"] ||
          island_width <
            ISLAND_INFO_VALIDATION.width.min[projectInfo?.unit || "mm"]
        ) {
          return true;
        }
        return (
          value < roundOff((width - island_width) / 2) ||
          this.createError({
            path: this.path,
            message: `Value must be less than ${roundOff(
              (width - island_width) / 2
            )}`,
          })
        );
      }),
  });

  const validationSchema: any = Yup.object().shape({
    length: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.length.min[projectInfo?.unit || "mm"],
        `Value must be atleast ${
          POCKET_VALIDATION.length.min[projectInfo?.unit || "mm"]
        }`
      ),
    width: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.width.min[projectInfo?.unit || "mm"],
        `Value must be atleast ${
          POCKET_VALIDATION.width.min[projectInfo?.unit || "mm"]
        }`
      ),
    depth: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.depth.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          POCKET_VALIDATION.depth.min[projectInfo?.unit || "mm"]
        }`
      ),
    quantity: QUANTITY_VALIDATION,
    min_distance: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.min_distance.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          POCKET_VALIDATION.min_distance.min[projectInfo?.unit || "mm"]
        }`
      )
      .test("is-less-than-eqaul-to-min", function (value: any) {
        const { width = 0, length = 0 } = this.parent;
        if (!width && !length) {
          return true;
        }
        const min = Math.min(width, length);

        if (min <= POCKET_VALIDATION.width.min[projectInfo?.unit || "mm"])
          return true;
        return (
          value <= min ||
          this.createError({
            path: this.path,
            message: `Value must be ${min} or less`,
          })
        );
      }),
    taper_angle: TAPER_ANGLE_VALIDATION,
    corner_radius: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.corner_radius.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          POCKET_VALIDATION.corner_radius.min[projectInfo?.unit || "mm"]
        }`
      )
      .when("min_distance", (min_distance, schema) => {
        return min_distance
          ? schema.max(min_distance, `Value must be ${min_distance} or less`)
          : schema;
      }),
    floor_radius: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.floor_radius.min[projectInfo?.unit || "mm"],
        `Value must be at least ${
          POCKET_VALIDATION.floor_radius.min[projectInfo?.unit || "mm"]
        }`
      )
      .when("depth", (depth, schema) => {
        return depth
          ? schema.max(depth, `Value must be ${depth} or less`)
          : schema;
      }),
    island_count: Yup.number()
      .required("This field is required")
      .min(0, "Value must be atleast 0")
      .when("length", {
        is: (length: number) =>
          length <= (projectInfo?.unit === "mm" ? 0.3 : 0.0118),
        then: Yup.number().max(
          0,
          `Pocket length must be at least ${
            projectInfo?.unit === "mm" ? 0.3 : 0.0118
          } to define an island`
        ),
        otherwise: Yup.number().max(4, "Value must be less than 5"),
      }),
    min_distance_between_islands: Yup.number()
      .required("This field is required")
      .min(
        POCKET_VALIDATION.min_distance_between_islands.min[
          projectInfo?.unit || "mm"
        ],
        `Value must be at least ${
          POCKET_VALIDATION.min_distance_between_islands.min[
            projectInfo?.unit || "mm"
          ]
        }`
      ),
    islands: Yup.array().of(islandSchema),
    vertical_clearance: POSITIVE_VALIDATION,
    horizontal_clearance: POSITIVE_VALIDATION,
  });

  const {
    handleBlur,
    handleChange,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_2d_pocket,
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!feature_info) return;

    let formValues: any = {};
    Object.keys(init_2d_pocket).forEach((key: string) => {
      switch (key) {
        case "min_distance_between_islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? "",
          };
          break;
        case "islands":
          formValues = {
            ...formValues,
            [key]: feature_info?.island_info?.[key] ?? [],
          };
          break;
        case "island_count":
          formValues = {
            ...formValues,
            [key]: feature_info.island_info?.islands?.length || 0,
          };
          break;
        case "use_corner_radius":
          formValues = {
            ...formValues,
            [key]:
              "corner_radius" in feature_info &&
              feature_info["corner_radius"] !== null,
          };
          break;
        default:
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ?? "",
          };
          break;
      }
    });

    // if (!pocketList?.[index]) return;
    // let formValues: any = {};
    // Object.keys(init_2d_pocket).forEach((key: string) => {
    //   formValues = {
    //     ...formValues,
    //     [key]: pocketList[index][key],
    //   };
    // });
    // setIsLandCount(formValues?.islands?.length);
    setValues(formValues);
  }, [setupNumber, feature_id]);

  // useEffect(() => {
  //   if (isLandCount > -1) {
  //     let arr: any[] = [];
  //     [...Array(Number(isLandCount))].forEach(() => {
  //       arr.push({...ISLAND_DATA});
  //     });
  //     setFieldValue("islands", arr);
  //   }
  // }, [isLandCount]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];
    let updatedIslandCount: number;

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "min_distance_between_islands":
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            island_info: {
              ...updated_feature.island_info,
              [key]: Number(value),
            },
          };
        } else {
          delete updated_feature.island_info.min_distance_between_islands;
        }
        break;
      case "islands":
        updated_feature = {
          ...updated_feature,
          island_info: {
            ...updated_feature.island_info,
            [key]: value,
          },
        };
        break;
      case "island_count":
        updatedIslandCount = Number(value);
        if (updatedIslandCount === 0) {
          const sameRefEmptyArray: any = [];
          delete updated_feature.island_info;
          setFieldValue("islands", sameRefEmptyArray);
          setFieldValue("island_count", 0);
          setFieldValue("min_distance_between_islands", "");
        } else if (updatedIslandCount > 0) {
          const currentIslandCount =
            updated_feature.island_info?.islands?.length ?? 0;
          const countDifference = Math.abs(
            updatedIslandCount - currentIslandCount
          );
          if (currentIslandCount === 0) {
            updated_feature.island_info = { islands: [] };
          }
          for (let i = 0; i < countDifference; i++) {
            if (updatedIslandCount - currentIslandCount >= 0) {
              updated_feature.island_info.islands.push({});
            } else {
              updated_feature.island_info.islands.pop();
            }
          }
        }
        if (updated_feature.island_info?.islands?.length <= 1) {
          delete updated_feature.island_info.min_distance_between_islands;
          setFieldValue("min_distance_between_islands", "");
        }
        setFieldValue("islands", updated_feature?.island_info?.islands ?? []);
        break;
      case "pocket_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        if (value === "closed") {
          setFieldValue("use_corner_radius", "");
          setFieldValue("no_of_walls", "");
          delete updated_feature.no_of_walls;
        } else if (value === "open") {
          delete updated_feature.min_distance;
          delete updated_feature.corner_radius;
          setFieldValue("min_distance", "");
          setFieldValue("corner_radius", "");
        }
        break;
      case "use_corner_radius":
        if (value) {
          updated_feature = {
            ...updated_feature,
            corner_radius: "",
          };
        } else {
          setFieldValue("corner_radius", "");
          delete updated_feature.corner_radius;
        }
        break;
      case "wall_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        if (value === "flat" || value === "curved") {
          delete updated_feature.taper_angle;
          setFieldValue("taper_angle", "");
        }
        break;
      case "floor_type":
        const sameRefEmptyArray: any = [];
        if (value === "through") {
          delete updated_feature.floor_radius;
          delete updated_feature.island_info;
          setFieldValue("floor_radius", "");
          setFieldValue("islands", sameRefEmptyArray);
          setFieldValue("island_count", 0);
          setFieldValue("min_distance_between_islands", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_POCKET_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  const handleIslandDataChange = (field: string, index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      // Handle the special case for "." input.
      if (value === ".") {
        value = "0.";
      }

      const data: any = [...(values?.islands ?? [])];

      if (e.target.value) {
        data[index][field] = value;
      } else {
        delete data[index]?.[field];
      }
      setFieldValue("islands", data);
    };
  };

  const handleIslandDataBlur = (field: string, index: number) => {
    let data: any = [...(values?.islands ?? [])];
    data[index][field] = +data[index][field];
    handleChangeFunc("islands", data);
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_2d_pocket).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("pocket", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Width"
              className=""
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="width"
              value={values?.width}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("width", values?.width);
              }}
              infoTooltipTitle={TooltipContent("pocket", "width")}
              invalid={touched.width && errors.width}
              helperText={touched.width && errors.width}
              regex={decimalRegExp}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Depth"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="depth"
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              infoTooltipTitle={TooltipContent("pocket", "depth")}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="mr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("pocket", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px] pr-2 flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Pocket Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("pocket", "pocketType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={POCKET_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("pocket_type", val, true)
              }
              value={values?.pocket_type}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          {values?.pocket_type === "open" && (
            <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
              <div className="flex align-baseline mr-auto">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Use corner radius?
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("pocket", "cornerRadius")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={CONFIRM_OPTIONS}
                setValue={(val: any) =>
                  handleChangeFunc("use_corner_radius", val, true)
                }
                value={values?.use_corner_radius}
              />
            </div>
          )}
          {values?.pocket_type === "closed" && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Min distance"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="min_distance"
                value={values?.min_distance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("min_distance", values?.min_distance);
                }}
                infoTooltipTitle={TooltipContent("pocket", "minDistance")}
                invalid={touched.min_distance && errors.min_distance}
                helperText={touched.min_distance && errors.min_distance}
                regex={decimalRegExp}
              />
            </div>
          )}
          {(values?.use_corner_radius || values?.pocket_type === "closed") && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Corner Radius"
                className=""
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="corner_radius"
                value={values?.corner_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("corner_radius", values?.corner_radius);
                }}
                infoTooltipTitle={TooltipContent("pocket", "cornerRadius")}
                invalid={touched.corner_radius && errors.corner_radius}
                helperText={touched.corner_radius && errors.corner_radius}
                regex={decimalRegExp}
              />
            </div>
          )}
        </div>
        {values?.pocket_type === "open" && (
          <div className="flex flex-row mt-4">
            <div className="flex justify-between items-center w-6/12 py-[14px] pr-2 flex-wrap">
              <div className="flex align-baseline mr-auto">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Number Of Walls
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("pocket", "numberOfWalls")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={POCKET_NO_OF_WALLS_OPTIONS}
                setValue={(val: any) =>
                  handleChangeFunc("no_of_walls", Number(val), true)
                }
                value={values?.no_of_walls}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] pr-2 flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Wall Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("pocket", "wallType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={POCKET_WALL_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("wall_type", val, true)}
              value={values?.wall_type}
            />
          </div>
          {values?.wall_type === "tapered" && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Taper Angle"
                className=""
                type="text"
                name="taper_angle"
                value={values?.taper_angle}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("taper_angle", values?.taper_angle);
                }}
                infoTooltipTitle={TooltipContent("pocket", "taperAngle")}
                invalid={touched.taper_angle && errors.taper_angle}
                helperText={touched.taper_angle && errors.taper_angle}
                regex={decimalRegExp}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Floor Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Floor Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("pocket", "floorType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={POCKET_FLOOR_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("floor_type", val, true)}
              value={values?.floor_type}
            />
          </div>

          {values?.floor_type === "solid" && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Floor Radius"
                className=""
                type="text"
                name="floor_radius"
                unit={projectInfo?.unit || "mm"}
                value={values?.floor_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("floor_radius", values?.floor_radius);
                }}
                infoTooltipTitle={TooltipContent("pocket", "floorRadius")}
                invalid={touched.floor_radius && errors.floor_radius}
                helperText={touched.floor_radius && errors.floor_radius}
                regex={decimalRegExp}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("pocket", "verticalClearance")}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("pocket", "horizontalClearance")}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      {values?.floor_type === "solid" && (
        <div className="mt-4">
          <p className="font-bold text-base text-gray-202">Island Details</p>
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="No. of islands"
                className="mr-2"
                type="text"
                name="island_count"
                value={values.island_count}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("island_count", values?.island_count);
                }}
                infoTooltipTitle={TooltipContent("pocket", "noOfIslands")}
                invalid={touched.island_count && errors.island_count}
                helperText={touched.island_count && errors.island_count}
                regex={onlyNumberRegexExp}
              />
            </div>

            {values.island_count > 1 && (
              <div className="w-6/12">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Min distance between islands"
                  className=""
                  type="text"
                  unit={projectInfo?.unit || "mm"}
                  name="min_distance_between_islands"
                  value={values?.min_distance_between_islands}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc(
                      "min_distance_between_islands",
                      values?.min_distance_between_islands
                    );
                  }}
                  infoTooltipTitle={TooltipContent(
                    "pocket",
                    "minDistanceBetweenIslands"
                  )}
                  invalid={
                    touched.min_distance_between_islands &&
                    errors.min_distance_between_islands
                  }
                  helperText={
                    touched.min_distance_between_islands &&
                    errors.min_distance_between_islands
                  }
                  regex={decimalRegExp}
                />
              </div>
            )}
          </div>

          {!!values?.islands?.length &&
            values?.islands?.map((item: any, index: number) => (
              <div className="flex flex-col" key={index}>
                <div className="py-3 border-b border-black">
                  <p className="font-semibold">Island #{index + 1} details</p>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      name={`islands[${index}].length`}
                      placeholder="Length"
                      className="mr-2"
                      type="text"
                      unit={projectInfo?.unit || "mm"}
                      value={item?.length}
                      onChange={handleIslandDataChange("length", index)}
                      onBlur={(e: any) => {
                        handleIslandDataBlur("length", index);
                      }}
                      infoTooltipTitle={TooltipContent(
                        "pocket",
                        "islandLength"
                      )}
                      invalid={
                        (touched?.islands?.[index] as any)?.length &&
                        (errors?.islands?.[index] as any)?.length
                      }
                      helperText={
                        (touched?.islands?.[index] as any)?.length &&
                        (errors?.islands?.[index] as any)?.length
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      name={`islands[${index}].width`}
                      placeholder="Width"
                      className=""
                      type="text"
                      unit={projectInfo?.unit || "mm"}
                      value={item?.width}
                      onChange={handleIslandDataChange("width", index)}
                      onBlur={(e: any) => {
                        handleIslandDataBlur("width", index);
                      }}
                      infoTooltipTitle={TooltipContent("pocket", "islandWidth")}
                      invalid={
                        (touched?.islands?.[index] as any)?.width &&
                        (errors?.islands?.[index] as any)?.width
                      }
                      helperText={
                        (touched?.islands?.[index] as any)?.width &&
                        (errors?.islands?.[index] as any)?.width
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      placeholder="Min x distance to face edges"
                      name={`islands[${index}].min_x_distance_to_face_edges`}
                      className="mr-2"
                      type="text"
                      unit={projectInfo?.unit || "mm"}
                      value={item?.min_x_distance_to_face_edges}
                      onChange={handleIslandDataChange(
                        "min_x_distance_to_face_edges",
                        index
                      )}
                      onBlur={(e: any) => {
                        handleIslandDataBlur(
                          "min_x_distance_to_face_edges",
                          index
                        );
                      }}
                      infoTooltipTitle={TooltipContent(
                        "pocket",
                        "minXDistanceTofaceEdges"
                      )}
                      invalid={
                        (touched?.islands?.[index] as any)
                          ?.min_x_distance_to_face_edges &&
                        (errors?.islands?.[index] as any)
                          ?.min_x_distance_to_face_edges
                      }
                      helperText={
                        (touched?.islands?.[index] as any)
                          ?.min_x_distance_to_face_edges &&
                        (errors?.islands?.[index] as any)
                          ?.min_x_distance_to_face_edges
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      placeholder="Min y distance to face edges"
                      className=""
                      name={`islands[${index}].min_y_distance_to_face_edges`}
                      unit={projectInfo?.unit || "mm"}
                      type="text"
                      value={item?.min_y_distance_to_face_edges}
                      onChange={handleIslandDataChange(
                        "min_y_distance_to_face_edges",
                        index
                      )}
                      onBlur={(e: any) => {
                        handleIslandDataBlur(
                          "min_y_distance_to_face_edges",
                          index
                        );
                      }}
                      infoTooltipTitle={TooltipContent(
                        "pocket",
                        "minYDistanceTofaceEdges"
                      )}
                      invalid={
                        (touched?.islands?.[index] as any)
                          ?.min_y_distance_to_face_edges &&
                        (errors?.islands?.[index] as any)
                          ?.min_y_distance_to_face_edges
                      }
                      helperText={
                        (touched?.islands?.[index] as any)
                          ?.min_y_distance_to_face_edges &&
                        (errors?.islands?.[index] as any)
                          ?.min_y_distance_to_face_edges
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-4">
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      placeholder="Floor radius"
                      className="mr-2"
                      name={`islands[${index}].floor_radius`}
                      type="text"
                      unit={projectInfo?.unit || "mm"}
                      value={item?.floor_radius}
                      onChange={handleIslandDataChange("floor_radius", index)}
                      onBlur={(e: any) => {
                        handleIslandDataBlur("floor_radius", index);
                      }}
                      infoTooltipTitle={TooltipContent(
                        "pocket",
                        "floorRadiusWithIsland"
                      )}
                      invalid={
                        (touched?.islands?.[index] as any)?.floor_radius &&
                        (errors?.islands?.[index] as any)?.floor_radius
                      }
                      helperText={
                        (touched?.islands?.[index] as any)?.floor_radius &&
                        (errors?.islands?.[index] as any)?.floor_radius
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                  <div className="w-6/12">
                    <Input
                      handleFocus={handleBlur}
                      placeholder="Height"
                      className=""
                      name={`islands[${index}].height`}
                      type="text"
                      unit={projectInfo?.unit || "mm"}
                      value={item?.height}
                      onChange={handleIslandDataChange("height", index)}
                      onBlur={(e: any) => {
                        handleIslandDataBlur("height", index);
                      }}
                      infoTooltipTitle={TooltipContent(
                        "pocket",
                        "islandHeight"
                      )}
                      invalid={
                        (touched?.islands?.[index] as any)?.height &&
                        (errors?.islands?.[index] as any)?.height
                      }
                      helperText={
                        (touched?.islands?.[index] as any)?.height &&
                        (errors?.islands?.[index] as any)?.height
                      }
                      regex={decimalRegExp}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default TwoDForm;
