import DefaultLayout from "Layouts/DefaultLayout";
import UserImg from "assets/images/user.png";
import { Auth, Storage } from "aws-amplify";
import AWS from "aws-sdk";
import Button from "components/Button/Button";
import ChangePasswordModal from "components/ChangePasswordModal/ChangePasswordModal";
import Input from "components/Input";
import PhoneInput from "components/PhoneInput";
import Switch from "components/Switch";
import { S3_CONFIG } from "config";
import { SET_LOADING_BAR, SET_PROFILE_IMG } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { BsPencil } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserAPI, updateUserAPI } from "services";
import { getFileExtension } from "utils";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({});

const Setting = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const hiddenFileInput = useRef<any>(null);
  const [getUser, loading, userData] = useApiCall(getUserAPI);
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [updateUser] = useApiCall(updateUserAPI);
  const navigate = useNavigate();
  
  // Hiding change password functionality
  // const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
  //   useState(false);

  useEffect(() => {
    if (!userInfo) return;
    getUser({
      userRecordId: userInfo?.["sub"],
      organizationId: userInfo?.["custom:organizationId"],
    });
  }, [userInfo]);

  useEffect(() => {
    if (!userData) return;
    setValues({
      ...values,
      ...userData,
    });
  }, [userData]);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_BAR,
      payload: loading,
    });
  }, [loading]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
    setValues,
  } = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      cellPhone: "",
      orgFullName: "",
      jobTitle: "",
      profileImgPath: "",
      isSubscription: false,
      address: "",
    },
    validationSchema,
    onSubmit: async () => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        let payload = {
          ...values,
          userRecordId: userInfo?.["sub"],
          organizationId: userInfo?.["custom:organizationId"],
        };
        if (
          values.profileImgPath &&
          typeof values.profileImgPath !== "string"
        ) {
          const stored = await Storage.put(
            `profileImg/${userInfo?.["custom:organizationId"]}/${
              userInfo?.["sub"]
            }/profile.${getFileExtension(
              (values.profileImgPath as any)?.name
            )}`,
            values.profileImgPath,
            {
              level: "public",
              contentType: (values.profileImgPath as any)?.type,
            }
          );
          payload.profileImgPath = stored?.key;
          const url = await Storage.get(stored?.key);
          dispatch({
            type: SET_PROFILE_IMG,
            payload: url,
          });
        }
        const res = await updateUser(payload);
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        navigate("/projects");
      } catch (err: any) {
        err?.message && toast.error(err?.message);
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
    },
  });

  useEffect(() => {
    if (!values?.profileImgPath) return;
    if (typeof values?.profileImgPath === "string")
      getImageUrl(values?.profileImgPath);
    else {
      setProfileImgUrl(URL.createObjectURL(values.profileImgPath));
    }
  }, [values?.profileImgPath]);

  const getImageUrl = async (key: string) => {
    try {
      const url = await Storage.get(key);
      setProfileImgUrl(url);
    } catch (error) {
      setProfileImgUrl("");
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChangeInput = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded) {
      setFieldValue("profileImgPath", fileUploaded);
    }
  };

  return (
    <DefaultLayout>
      <form className="w-full relative h-full" onSubmit={handleSubmit}>
        <div className="absolute flex flex-row w-full px-4 py-3 left-0 bottom-[60px] right-0 top-0 space-x-6 overflow-y-auto">
          <div className="w-4/12">
            <div className="w-full relative">
              <input
                type="file"
                onChange={handleChangeInput}
                ref={hiddenFileInput}
                className="invisible"
                accept="image/*"
              />
              <img
                src={!!values.profileImgPath ? profileImgUrl : UserImg}
                className="w-full aspect-square rounded-lg shadow-profile-img"
                onError={(
                  event: React.SyntheticEvent<HTMLImageElement, Event>
                ) => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = UserImg;
                }}
                alt="profile"
              />
              <Button
                className="!p-2 bg-white shadow-edit-btn !rounded-full absolute bottom-[8px] right-[8px]"
                variant="ghost"
                onClick={handleClick}
              >
                <BsPencil className="w-4 h-4 text-gray-344" />
              </Button>
            </div>
          </div>
          <div className="w-8/12 flex flex-col space-y-4">
            <div className="border-b border-gray-b9b">
              <p className="font-bold text-gray-344 mb-4">My Profile</p>
            </div>

            <div className="flex flex-row items-center space-x-4">
              <Input
                dataType="string"
                handleFocus={handleBlur}
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="First Name"
                name="firstName"
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.firstName && errors.firstName}
                helperText={touched.firstName && errors.firstName}
              />
              <Input
                dataType="string"
                handleFocus={handleBlur}
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="Last Name"
                name="lastName"
                value={values?.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.lastName && errors.lastName}
                helperText={touched.lastName && errors.lastName}
              />
            </div>

            <div className="flex flex-row items-center space-x-4">
              <Input
                dataType="string"
                handleFocus={handleBlur}
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="Email"
                type="email"
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.email && errors.email}
                helperText={touched.email && errors.email}
                disabled={true}
              />
              <PhoneInput
                onlyCountries={["us", "ca", "de", "fr", "es", "gb", "in"]}
                label="Contact Number"
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                name="cellPhone"
                value={values?.cellPhone}
                onChange={(val: string) => {
                  setFieldValue("cellPhone", val);
                }}
                onBlur={handleBlur}
                invalid={touched.cellPhone && errors.cellPhone}
                helperText={touched.cellPhone && errors.cellPhone}
              />
            </div>

            {/* <div className="flex flex-row items-center space-x-4">
              <Input
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="Company name"
                name="orgFullName"
                value={values?.orgFullName}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.orgFullName && errors.orgFullName}
                helperText={touched.orgFullName && errors.orgFullName}
              />
              <Input
   handleFocus={handleBlur}
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="Job Title"
                name="jobTitle"
                value={values?.jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.jobTitle && errors.jobTitle}
                helperText={touched.jobTitle && errors.jobTitle}
                disabled
              />
            </div> */}

            {/* <div className="flex flex-row items-center space-x-4 pb-4">
              <Input
   handleFocus={handleBlur}
                className="w-full"
                subClassName="!shadow-form-input !bg-white"
                inputClassName="bg-transparent"
                label="Address"
                inputType="textarea"
                rows="5"
                name="address"
                value={values?.address}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.address && errors.address}
                helperText={touched.address && errors.address}
              />
            </div> */}

            {/* <div>
              <Switch label="Subscription" enabled={values.isSubscription} />
            </div> */}
            {/* <div className="flex flex-row space-x-4 pb-4">
              <div className="w-full">
                <p className="text-black font-medium text-[10px] mb-2">
                  Subscription status
                </p>
                <div className="border border-gray-fff rounded shadow-box-2 py-1.5 px-3 space-y-1">
                  <div className="text-xs font-medium text-gray-475 flex flex-row items-center">
                    <span className="mr-1">Status:</span>
                    <span className="text-grean-039">Active</span>
                  </div>
                  <div className="text-xs font-medium text-gray-475 flex flex-row items-center">
                    <span className="mr-1">Term:</span>
                    <span className="text-gray-344">Yearly</span>
                  </div>
                  <div className="text-xs font-medium text-gray-475 flex flex-row items-center">
                    <span className="mr-1">Renewal date:</span>
                    <span className="text-gray-344">October 23 2023</span>
                  </div>
                  <div className="text-xs font-medium text-gray-475 flex flex-row items-center">
                    <span className="mr-1">Renewal billing date:</span>
                    <span className="text-gray-344">October 21 2023</span>
                  </div>
                  <div className="text-xs font-medium text-gray-475 flex flex-row items-center">
                    <span className="mr-1">Automatic renewal:</span>
                    <Switch label="" enabled={values.isSubscription} />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-row items-center justify-between mb-2">
                  <span className="text-black font-medium text-[10px] ">
                    Payment option
                  </span>
                  <span className="text-surface-default font-medium text-[10px]">
                    Manage payment
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Footer */}
        <div className="fixed bg-white left-0 right-0 h-[60px] bottom-[22px] border-t border-gray-eac px-4 flex items-center justify-end">
          {/* <Button
            variant="ghost"
            className="!p-0 font-bold text-sm underline"
            onClick={() => setIsChangePasswordModalOpen(true)}
          >
            Change Password?
          </Button> */}
          <div className="flex items-center space-x-4">
            <Button
              size="md"
              className="!rounded-full py-[6px] px-5 !bg-gray-f2f !text-black-222 font-semibold"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              size="md"
              className="!rounded-full py-[6px] px-5 whitespace-nowrap font-semibold"
              type="submit"
              disabled={!dirty || !isValid}
            >
              Update
            </Button>
          </div>
        </div>
      </form>
      {/* <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
      /> */}
    </DefaultLayout>
  );
};

export default Setting;
