import RadioComponent from "components/RadioComponent";
import { EDGE_OPTIONS, SET_MACHINE_SETUP } from "constant";
import { GlobalContext } from "context/GlobalContext";
import { useContext, useEffect, useState } from "react";
import ChamferForm from "./ChamferForm";
import FilletForm from "./FilletForm";

interface IEdgeForm {
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const EdgeForm: React.FC<IEdgeForm> = ({ feature_id, setAllFieldsTouched }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, setupNumber } = state;
  const [selectedFeature, setSelectedFeature] = useState<any>(null);

  useEffect(() => {
    if (setupNumber < 0 || !feature_id) return;
    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    const feature_idx = machiningSetups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );
    const feature_data = {
      ...machiningSetups?.[setupIdx]?.features?.[feature_idx]
        ?.featureInformation?.feature_data,
    };
    setSelectedFeature(feature_data);
  }, [setupNumber, feature_id]);

  const handleEdgeOption = (val: string) => {
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: 'type',
    //     value: val
    //   }
    // })
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_data = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data,
    };
    updated_data = {
      ...updated_data,
      feature_name: val,
      // Setting default value for quantity.
      feature_info: {
        quantity: 1,
      },
    };
    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data = updated_data;

    setSelectedFeature(updated_data);

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  return (
    <div>
      <RadioComponent
        options={EDGE_OPTIONS}
        setValue={handleEdgeOption}
        value={selectedFeature?.feature_name}
        labelClassName="!mr-4"
        showChangeWarning
      />
      {selectedFeature?.feature_name === "fillet" && (
        <FilletForm
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
          setAllFieldsTouched={setAllFieldsTouched}
        />
      )}
      {selectedFeature?.feature_name === "chamfer" && (
        <ChamferForm
          feature_info={selectedFeature?.feature_info}
          feature_id={selectedFeature?.feature_id}
          setAllFieldsTouched={setAllFieldsTouched}
        />
      )}
    </div>
  );
};

export default EdgeForm;
