import { MouseEventHandler, useContext } from "react";
import { GlobalContext } from "context";
import { capitalize, convertKeyToLabel } from "utils";
import { featureInputsWithNoUnits, makeLabels } from "constant";

interface IFeatureDataSideBar {
  featureData: any;
  hideSidebar: MouseEventHandler;
}

const FeatureDataSideBar: React.FC<IFeatureDataSideBar> = ({
  featureData,
  hideSidebar,
}) => {
  const { state } = useContext(GlobalContext);
  const { mappingForMachiningSetups, projectInfo } = state;
  const labelsArray = makeLabels(
    featureData.featureName,
    featureData.featureInfo
  );
  return (
    <div className="block inline-flex w-[300px] border border-gray-200 rounded-[8px] p-[8px] h-[calc(100vh-200px)] overflow-y-scroll flex-col">
      <div className="flex items-center w-full h-auto">
        <div className="text-[16px] font-semibold text-blue-600 underline inline-flex mr-auto cursor-pointer">
          {mappingForMachiningSetups[featureData.setupId]?.name ?? "--"}-
          {mappingForMachiningSetups[featureData.setupId]?.programName ?? "--"}-
          {mappingForMachiningSetups[featureData.setupId]?.revisionName ??
            "REV A"}
        </div>
        <svg
          className="cursor-pointer"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={hideSidebar}
        >
          <path
            d="M14.0625 5.9375L5.9375 14.0625M5.9375 5.9375L14.0625 14.0625"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="py-[8px] flex flex-col gap-[8px]">
        <div className="text-gray-700 text-[14px] font-semibold">
          <span className="text-gray-400">
            {featureData.posNo}/{featureData.totalNo}
          </span>{" "}
          {featureData.featureTitle} ({featureData.operation})
        </div>
        <div className="w-full border-b border-gray-200 pb-[8px]">
          <table className="w-full">
            {labelsArray?.map(({ key, label }: any, idx: number) => {
              const unit = !featureInputsWithNoUnits.has(key)
                ? " " + projectInfo?.unit
                : "";
              if (key === "island_info") {
                if (featureData.featureInfo[key].islands.length > 1) {
                  return (
                    <tr key={idx}>
                      <th className="text-[12px] font-semibold text-gray-700 text-left py-[2px]">
                        <div className="flex gap-[4px] items-center">
                          {convertKeyToLabel("min_distance_between_islands")}
                        </div>
                      </th>
                      <td className="text-[12px] font-bold text-gray-700 text-left py-[2px]">
                        :{" "}
                        {`${featureData.featureInfo[key].min_distance_between_islands}${unit}`}
                      </td>
                    </tr>
                  );
                } else return null;
              } else {
                return (
                  <tr key={idx}>
                    <th className="text-[12px] font-semibold text-gray-700 text-left py-[2px]">
                      <div className="flex gap-[4px] items-center">{label}</div>
                    </th>
                    <td className="text-[12px] font-bold text-gray-700 text-left py-[2px]">
                      : {`${capitalize(featureData.featureInfo[key])}${unit}`}
                    </td>
                  </tr>
                );
              }
            })}
          </table>
        </div>
        {featureData.featureInfo?.island_info?.islands?.map(
          (island: any, idx1: number) => {
            return (
              <div
                className="w-full rounded-[4px] p-[4px] border-gray-200 bg-gray-50 border gap-[6px] flex flex-col"
                key={idx1}
              >
                <div className="text-[12px] font-bold text-gray-700">
                  Island {idx1 + 1}
                </div>
                <table className="w-full">
                  {Object.entries(island).map(
                    ([key, value]: any, idx2: number) => {
                      const unit = !featureInputsWithNoUnits.has(key)
                        ? " " + projectInfo?.unit
                        : "";
                      return (
                        <tr key={idx2}>
                          <th className="text-[12px] font-semibold text-gray-700 text-left py-[2px]">
                            {convertKeyToLabel(key)}
                          </th>
                          <td className="text-[12px] font-bold text-gray-700 text-left py-[2px]">
                            : {`${value}${unit}`}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </table>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default FeatureDataSideBar;
