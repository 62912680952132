import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useContext, useEffect } from "react";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";

interface IAddSetupModal {
  isOpen: boolean;
  setIsOpen: any;
  onAddSetup: Function;
}

const AddSetupModal: React.FC<IAddSetupModal> = ({
  isOpen,
  setIsOpen,
  onAddSetup,
}) => {
  const { state } = useContext(GlobalContext);
  const { mappingForMachiningSetups } = state;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(1)
      .test("unique-validation", "Setup name should be unique", (val: any) => {
        for (const setupId in mappingForMachiningSetups) {
          if (
            val?.trim() === mappingForMachiningSetups?.[setupId]?.name?.trim()
          )
            return false;
        }
        return true;
      })
      .required("This field is required"),
    programName: Yup.string()
      .min(1)
      .test(
        "unique-validation",
        "Program name should be unique",
        (val: any) => {
          for (const setupId in mappingForMachiningSetups) {
            if (
              val?.trim() ===
              mappingForMachiningSetups?.[setupId]?.programName?.trim()
            )
              return false;
          }
          return true;
        }
      )
      .required("This field is required"),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    setValues,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      name: "",
      programName: "",
    },
    validationSchema,
    onSubmit: () => {
      onAddSetup(values?.name, values?.programName);
      setIsOpen(false);
    },
  });

  useEffect(() => {
    if (!isOpen) return;
    setValues({
      name: "",
      programName: "",
    });
    setFieldTouched("name", false);
    setFieldTouched("programName", false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="custom">
      <Modal.Header>
        <div className="flex flex-row items-center justify-between p-[16px] border-b border-gray-300">
          <h3 className="text-lg font-medium leading-6 text-black-222">
            Add Setup
          </h3>
          <button data-autofocus>
            <FiX
              className="w-6 h-6 cursor-pointer text-black-222"
              onClick={() => setIsOpen(false)}
            />
          </button>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="p-[16px]">
          <table className="w-full">
            <tbody>
              <tr>
                <td className="p-[4px] w-[42px] align-top">
                  <div className="bg-gray-100 border  border-gray-200  text-gray-700 p-[8px] rounded-[8px] text-[14px] font-semibold leading-[20px]">
                    S{Object.keys(mappingForMachiningSetups)?.length + 1}
                  </div>
                </td>
                <td className="p-[4px]  align-top">
                  <Input
                    dataType="string"
                    handleFocus={handleBlur}
                    className="w-full"
                    name="name"
                    value={values?.name}
                    placeholder="Enter setup name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName="leading-[20px]"
                    size="small"
                    isErrorMessageAbsolute={true}
                    invalid={touched.name && errors.name}
                    helperText={touched.name && errors.name}
                  />
                </td>
                <td className="p-[4px]  align-top">
                  <Input
                    dataType="string"
                    handleFocus={handleBlur}
                    className="w-full"
                    name="programName"
                    value={values?.programName}
                    placeholder="Enter program name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputClassName="leading-[20px]"
                    isErrorMessageAbsolute={true}
                    size="small"
                    invalid={touched.programName && errors.programName}
                    helperText={touched.programName && errors.programName}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className="!mt-0">
          <div className="flex justify-center w-full p-[16px] border-t border-gray-300">
            <Button
              size="md"
              className="modal-button"
              type="submit"
              disabled={!isValid}
            >
              Add
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddSetupModal;
