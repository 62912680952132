import { Box, Modal } from "@mui/material";
import Button from "components/Button";
import FeatureCheckbox from "components/FeatureCheckbox";
import { GlobalContext } from "context";
import { useContext, useState } from "react";
import WarningSign from "assets/images/icons/uil_exclamation-triangle.svg";
import { IRemindSetupAssignmentModal, modalStyles } from "./utils";

const RemindSetupAssignmentModal: React.FC<IRemindSetupAssignmentModal> = ({
  isOpen,
  setIsOpen,
}) => {
  const { state } = useContext(GlobalContext);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { userInfo } = state;
  const continueHandler = () => {
    setIsOpen(false);
    if (!dontShowAgain || !userInfo?.email) return;
    const doNotAskData = JSON.parse(
      localStorage.getItem("dontShowAssignmentWarningChecked") || "{}"
    );
    // Update the user's status
    doNotAskData[userInfo.email] = true;
    // Save the updated object back to localStorage
    localStorage.setItem(
      "dontShowAssignmentWarningChecked",
      JSON.stringify(doNotAskData)
    );
  };
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={modalStyles}>
        <div className="max-w-[440px] rounded-[16px] bg-white flex flex-col gap-4 p-4">
          <div className="flex flex-col items-center justify-between p-4 pt-0 gap-2">
            <img src={WarningSign} alt="" />
            <h3 className="text-lgleading-6 text-gray-700 font-bold">
              Setups Assignment
            </h3>
            <div className="text-[14px]">
              <ol className="list-decimal">
                <li className="mb-2">
                  <b>Individual Assignment:</b>
                  <ul className="list-disc">
                    <li>
                      {" "}
                      Use the Assign Setup option on the right side of each
                      feature operation to assign a setup individually.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Bulk Assignment:</b>
                  <ul className="list-disc">
                    <li>
                      Select multiple features or feature operations using the
                      checkboxes on the left.
                    </li>
                    <li>
                      Click the Move to option at the top-right corner to assign
                      them to setups.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          <div className="flex justify-between items-baseline">
            <div className="flex gap-2">
              <div>
                <FeatureCheckbox
                  checked={dontShowAgain}
                  onChange={(e: any) => {
                    setDontShowAgain(e.target.value);
                  }}
                />
              </div>
              <div className="text-[12px] text-gray-700">Don't show again</div>
            </div>
            <Button className="modal-button" onClick={continueHandler}>
              Ok
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RemindSetupAssignmentModal;
