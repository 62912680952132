import ProjectItemList from "components/ProjectItemList";
import { keyList, SET_ASSIGNED_SETUPS, SET_MACHINE_SETUP } from "constant";
import { GlobalContext } from "context";
import DefaultLayout from "Layouts/DefaultLayout";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createDeepCopy } from "utils";
import FeatureAccordian from "../FeatureAccordian";
import FeatureCheckbox from "components/FeatureCheckbox";
import { assignSetup } from "./utils";
import SetupsAssignErrorModal from "components/SetupsAssignErrorModal";
import ConfirmPopUp from "components/MachiningStrategySummaryBox/ConfirmPopUp";
import SetupLevelOrder from "components/SetupLevelOrder";
import Button from "components/Button";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import DropDownMenu from "components/DropdownMenu";
import { toast } from "react-toastify";
import RemindSetupAssignmentModal from "components/RemindSetupAssignmentModal";

const ManufacturingProcess = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    machiningSetups,
    assignedMachiningSetups,
    mappingForMachiningSetups,
    visiblefeature_ids,
    userInfo,
  } = state;
  const [selectedSetupId, setSelectedSetupId] = useState("");
  const [featureList, setFeatureList] = useState<any>({});
  const [selectedOperations, setSelectedOperations] = useState(
    new Set() as Set<string>
  );
  const [totalOperations, setTotalOperations] = useState(0);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showResetSetupsPopUp, setShowResetSetupsPopUp] = useState(false);
  const [totalUnassignedOperations, setTotalUnassignedOperations] = useState(0);
  const location = useLocation();
  const dontShowAssignmentWarningChecked = JSON.parse(
    localStorage.getItem("dontShowAssignmentWarningChecked") || "{}"
  )[userInfo?.email];
  const [isRemindSetupAssignmentModalOpen, setRemindSetupAssignmentModalOpen] =
    useState(
      location?.state?.referrer === "machine" &&
        !dontShowAssignmentWarningChecked
    );
  const visiblefeatureIdsSet = new Set(visiblefeature_ids?.[0]);
  function selectAllOperations() {
    let selectedOperations: Set<string> = new Set();
    for (const featureId in featureList) {
      featureList[featureId]?.operations?.forEach((operation: any) => {
        if (!operation?.assignSetUpId) {
          selectedOperations.add(
            `${featureId}<>${operation.strategyIndex}<>${operation.passIndex}<>${operation.operationIndex}`
          );
        }
      });
    }
    setSelectedOperations(selectedOperations);
  }

  const navigate = useNavigate();
  const handleRedirect = (tab: string) => {
    if (selectedSetupId) setupChangeHandler(selectedSetupId);
    navigate(`/project?tab=${tab}`);
  };
  useEffect(() => {
    // traverse assignedSetupsObject to check if it contains any deleted feature
    // or operation from strategy which is not selected.

    let updated_setups = createDeepCopy(machiningSetups);
    let updatedAssignedSetups = createDeepCopy(assignedMachiningSetups);
    for (const setupId in updatedAssignedSetups) {
      updatedAssignedSetups[setupId] = updatedAssignedSetups[setupId]?.filter(
        (operation: any) => {
          const featureIndex = updated_setups?.[0]?.features?.findIndex(
            (feature: any) => feature?.feature_id === operation?.featureId
          );
          if (featureIndex === -1) return false;

          // handling features not selected on feature summary
          if (!visiblefeatureIdsSet.has(operation?.featureId)) {
            const currentOperation =
              updated_setups?.[0]?.features?.[featureIndex]?.featureInformation
                ?.strategies?.[0]?.machining_strategy?.[
                operation?.strategyIndex
              ]?.passes?.[operation.passIndex]?.operations?.[
                operation?.operationIndex
              ];

            if (currentOperation) {
              delete currentOperation.assignedSetupId;
              delete currentOperation.pass_number;
            }

            return false;
          }
          return (
            updated_setups?.[0]?.features?.[featureIndex]?.featureInformation
              ?.strategies?.[0]?.machining_strategy?.[operation?.strategyIndex]
              ?.isSelected &&
            updated_setups?.[0]?.features?.[featureIndex]?.featureInformation
              ?.strategies?.[0]?.machining_strategy?.[operation?.strategyIndex]
              ?.passes?.[operation.passIndex]?.operations[
              operation?.operationIndex
            ]?.assignedSetupId
          );
        }
      );
      // changing assignedOperationIndex in updated machining Setups according to the
      // deleted operations in assignedSetupsObject

      updatedAssignedSetups?.[setupId]?.forEach(
        (operation: any, index: number) => {
          const featureIndex = updated_setups?.[0]?.features?.findIndex(
            (feature: any) => feature?.feature_id === operation?.featureId
          );
          updated_setups[0].features[
            featureIndex
          ].featureInformation.strategies[0].machining_strategy[
            operation.strategyIndex
          ].passes[operation?.passIndex].operations[operation?.operationIndex][
            "pass_number"
          ] = index;
        }
      );
    }

    //saving updated_setups in state
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    //state to maintain assignedSetups Object
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: updatedAssignedSetups,
    });
  }, []);
  useEffect(() => {
    if (!machiningSetups) return;
    let totalUnassignedOperations = 0;
    let totalOperations = 0;
    let updatedFeatureList: any = {};
    keyList.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[0]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      existing_features?.forEach((feature: any, index: number) => {
        const strategyIndex =
          feature.featureInformation?.strategies?.[0]?.machining_strategy?.findIndex(
            (item: any) => item?.isSelected
          );
        const selected_strategy =
          feature.featureInformation?.strategies?.[0]?.machining_strategy?.[
            strategyIndex
          ];
        if (
          !selected_strategy ||
          !visiblefeatureIdsSet.has(feature?.feature_id)
        )
          return;
        updatedFeatureList[
          feature?.featureInformation?.feature_data?.feature_id
        ] = {
          feature_info: feature?.featureInformation?.feature_data?.feature_info,
          name: `${title} ${index + 1}`,
          feature_id: feature?.featureInformation?.feature_data?.feature_id,
          type: type,
          feature_name: feature?.featureInformation?.feature_data?.feature_name,
          camSpecificNames: feature.featureInformation?.cam_specific_names,
          tag_id: feature.featureInformation?.tag_id,
          operations: [],
        };
        selected_strategy?.passes?.forEach((pass: any, id1: number) => {
          pass?.operations?.forEach((operation: any, id2: number) => {
            if (!operation.assignedSetupId) totalUnassignedOperations += 1;
            totalOperations += 1;
            const selectedToolPath =
              operation.tool_paths?.[operation.selectedToolPathInd ?? 0];
            updatedFeatureList[
              feature?.featureInformation?.feature_data?.feature_id
            ].operations.push({
              pass: pass.pass,
              location: operation.location,
              operation: operation.operation,
              tool_path: selectedToolPath?.tool_path,
              tool_path_type: selectedToolPath?.tool_path_type,
              tool_type:
                selectedToolPath?.tool_types?.[
                  operation.selectedToolTypeInd ?? 0
                ],
              tool_path_style: selectedToolPath?.tool_path_style ?? "N/A",
              passIndex: id1,
              operationIndex: id2,
              assignSetUpId: operation.assignedSetupId,
              pass_number: operation.pass_number,
              strategyIndex,
            });
          });
        });
      });
    });
    setTotalUnassignedOperations(totalUnassignedOperations);
    setTotalOperations(totalOperations);
    setFeatureList(updatedFeatureList);
  }, [machiningSetups]);

  function setupChangeHandler(setupId: string) {
    // set machiningSetups according to the changed order in assignedMachiningSetups after reordering.

    // traverse the assignedMachiningSetups and changing pass_number/assignedOperationIndex in the machiningSetups
    let updated_setups = createDeepCopy(machiningSetups);
    assignedMachiningSetups[setupId]?.forEach(
      (operation: any, index: number) => {
        const featureIndex = updated_setups?.[0]?.features?.findIndex(
          (feature: any) => feature?.feature_id === operation?.featureId
        );
        updated_setups[0].features[
          featureIndex
        ].featureInformation.strategies[0].machining_strategy[
          operation.strategyIndex
        ].passes[operation.passIndex].operations[operation.operationIndex][
          "pass_number"
        ] = index;
      }
    );
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  }

  function assignSetupsToSelectedOperations(setupId: string) {
    // traverse set
    let errorMessages: any = [];
    let updatedMachiningSetups = createDeepCopy(machiningSetups);
    let updatedAssignedSetups = createDeepCopy(assignedMachiningSetups);
    selectedOperations.forEach((operation: string) => {
      const [featureId, strategyIndex, passIndex, operationIndex] =
        operation.split("<>");
      const {
        changedMachiningSetups,
        changedAssignedMachiningSetups,
        errorMessage,
      } = assignSetup(
        Number(operationIndex),
        Number(passIndex),
        Number(strategyIndex),
        featureId,
        updatedAssignedSetups,
        updatedMachiningSetups,
        setupId,
        mappingForMachiningSetups
      );
      if (errorMessage) {
        errorMessages.push({
          heading: `${featureList[featureId].name} : ${featureList[featureId].operations[operationIndex].operation}`,
          message: errorMessage,
        });
      } else {
        updatedMachiningSetups = createDeepCopy(changedMachiningSetups);
        updatedAssignedSetups = createDeepCopy(changedAssignedMachiningSetups);
      }
    });
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: updatedAssignedSetups,
    });
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updatedMachiningSetups,
    });
    if (errorMessages.length) {
      setErrorMessages(errorMessages);
      setErrorModalIsOpen(true);
    }
    // empty selected operations set
    setSelectedOperations(new Set());
    // call assign to
    // save error messages
  }

  function resetSetups() {
    const updatedMachiningSetups = createDeepCopy(machiningSetups);
    const updatedAssignedSetups = createDeepCopy(assignedMachiningSetups);

    for (const setupId in updatedAssignedSetups) {
      updatedAssignedSetups[setupId].forEach((operation: any) => {
        const featureIndex = updatedMachiningSetups?.[0]?.features?.findIndex(
          (feature: any) => feature?.feature_id === operation?.featureId
        );
        delete updatedMachiningSetups[0].features[featureIndex]
          .featureInformation.strategies[0].machining_strategy[
          operation.strategyIndex
        ].passes[operation.passIndex].operations[operation.operationIndex][
          "pass_number"
        ];
        delete updatedMachiningSetups[0].features[featureIndex]
          .featureInformation.strategies[0].machining_strategy[
          operation.strategyIndex
        ].passes[operation.passIndex].operations[operation.operationIndex][
          "assignedSetupId"
        ];
      });
      updatedAssignedSetups[setupId] = [];
    }
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updatedMachiningSetups,
    });
    dispatch({
      type: SET_ASSIGNED_SETUPS,
      payload: updatedAssignedSetups,
    });
  }

  return (
    <DefaultLayout>
      <div className="flex flex-row justify-between items-center mt-4 mx-4 my-4">
        <span className="font-semibold text-xl">Manufacturing Process</span>
      </div>
      <div className="flex flex-col h-full overflow-hidden">
        <div className="flex w-full gap-[8px] px-[16px] h-full pb-[8px] oveflow-hidden">
          <ProjectItemList
            selectedSetupId={selectedSetupId}
            setSelectedSetupId={setSelectedSetupId}
            setupChangeHandler={setupChangeHandler}
          />
          {!selectedSetupId ? (
            <div className="w-full flex flex-col gap-[4px] h-full overflow-auto">
              <div className={`flex gap-3 justify-end`}>
                {!selectedOperations.size ? (
                  <Button
                    className={`bg-red-fee text-red-d92 !px-[12px] !py-[6px] border border-red-200 rounded-[8px] text-[14px]`}
                    onClick={() => {
                      setShowResetSetupsPopUp(true);
                    }}
                    disabled={totalOperations === totalUnassignedOperations}
                  >
                    Reset Setups
                  </Button>
                ) : (
                  <DropDownMenu
                    id={"move-to"}
                    handleButtonClick={
                      Object.keys(mappingForMachiningSetups ?? {}).length === 0
                        ? () => {
                            toast.error(
                              "Please create alteast 1 setup before assignment."
                            );
                          }
                        : null
                    }
                    label={"Move to"}
                    useArrowIcon={
                      !!Object.keys(mappingForMachiningSetups ?? {}).length
                    }
                    className="w-auto inline-flex gap-[4px] items-center drop-shadow-avatar border border-gray-200 rounded-[8px] py-[3px] px-[12px] text-gray-700 text-[14px] font-semibold whitespace-nowrap"
                    onClick={(setupId: string) => {
                      assignSetupsToSelectedOperations(setupId);
                    }}
                    menuItems={Object.keys(mappingForMachiningSetups ?? {}).map(
                      (setupId: string, ind: number) => {
                        return {
                          value: setupId,
                          label: `Setup ${ind + 1}`,
                        };
                      }
                    )}
                  />
                )}
              </div>
              <div className="flex items-center h-auto">
                <div className="checkboxinput inline-flex gap-[4px] items-center">
                  <FeatureCheckbox
                    disabled={totalUnassignedOperations === 0}
                    checked={!!selectedOperations.size}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        selectAllOperations();
                      } else setSelectedOperations(new Set() as Set<string>);
                    }}
                    notAll={
                      selectedOperations.size !== totalUnassignedOperations
                    }
                    label={
                      selectedOperations.size
                        ? `${selectedOperations.size} Operations Selected`
                        : "Select all"
                    }
                  />
                </div>
                <div className="inline-flex ml-auto text-gray-600 text-[12px] font-semibold">
                  Total Operations: {totalOperations}
                </div>
              </div>
              <div className="flex items-center gap-[4px] text-gray-500 text-[12px] font-semibold py-[4px]">
                <img src={FeatureInfoIcon} alt="" />
                Please assign your features or feature-operations under the
                desired setups.
              </div>
              <div className="relative overflow-x-auto border border-gray-200 rounded-[12px] max-h-full" id="features-container">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th
                        scope="col"
                        className="w-[68px] px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      ></th>
                      <th
                        scope="col"
                        className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      >
                        Tool path
                      </th>
                      <th
                        scope="col"
                        className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      >
                        Cutting pass
                      </th>
                      <th
                        scope="col"
                        className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      >
                        Operation
                      </th>
                      <th
                        scope="col"
                        className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      >
                        Tool type
                      </th>
                      <th
                        scope="col"
                        className="px-[8px] py-[12px] text-[12px] font-medium text-gray-600"
                      >
                        Setups
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {featureList &&
                      Object.keys(featureList)?.map(
                        (featureId: any, idx: number) => {
                          return (
                            <FeatureAccordian
                              key={`featureAcc-${featureId}`}
                              checked={idx === 0}
                              feature={featureList[featureId]}
                              selectedOperations={selectedOperations}
                              setSelectedOperations={setSelectedOperations}
                              setErrorMessages={setErrorMessages}
                              setErrorModalIsOpen={setErrorModalIsOpen}
                              errorModalIsOpen={errorModalIsOpen}
                            />
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <SetupLevelOrder setupId={selectedSetupId} />
          )}
        </div>
      </div>
      <div className="bg-white border-t justify-end mt-auto border-gray-200 px-4 py-2 flex font-semibold gap-4">
        <Button
          type="button"
          variant="gray"
          onClick={() => {
            handleRedirect(
              location.state?.referrer === "default-recommendation"
                ? "feature-summary"
                : "machine"
            );
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          disabled={!!totalUnassignedOperations}
          onClick={() => {
            handleRedirect("manufacturing-summary");
          }}
        >
          Next: Manufacturing Process Summary
        </Button>
      </div>
      <SetupsAssignErrorModal
        isOpen={errorModalIsOpen}
        setIsOpen={setErrorModalIsOpen}
        errorMessages={errorMessages}
      />
      <ConfirmPopUp
        showConfirmPopUp={showResetSetupsPopUp}
        closeConfirmPopUp={() => {
          setShowResetSetupsPopUp(false);
        }}
        confirmFunction={resetSetups}
        confirmMessage="Are you sure you want to unassign the setups?"
      />
      <RemindSetupAssignmentModal
        isOpen={isRemindSetupAssignmentModalOpen}
        setIsOpen={setRemindSetupAssignmentModalOpen}
      />
    </DefaultLayout>
  );
};

export default ManufacturingProcess;
