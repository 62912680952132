import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import uniqid from "uniqid";
import DragIcon from "assets/images/icons/drag-icon.svg";
import "./style.css";
import VerifyIcon from "assets/images/icons/verifyIcon";
import TrashIcon from "assets/images/icons/trash-icon.svg";
import DownloadIcon from "assets/images/icons/download-icon.svg";
import { FiCheckCircle } from "react-icons/fi";

export interface IAccordionProps {
  type?: string;
  checked?: boolean;
  onChange?: (e: any) => void;
  tabLabelChild: React.ReactNode;
  labelChildClass?: string;
  verify?: boolean;
  children: React.ReactNode;
  index?: number;
  className?: string;
  removeHole?: any;
  dragIcon?: boolean;
  labelClass?: string;
  subLabel?: React.ReactNode;
  isBackground?: boolean;
  count?: number;
  tabContentClassName?: string;
  isFinalScreenAccordian?: boolean;
  setupData?: any;
  key?: any;
  downloadHandler?: any;
}

const AccordionItem: React.FC<IAccordionProps> = ({
  type = "checkbox",
  checked,
  dragIcon = true,
  onChange,
  tabLabelChild,
  labelChildClass,
  verify = false,
  children,
  index,
  className,
  removeHole,
  labelClass,
  subLabel,
  isBackground,
  count = -1,
  tabContentClassName,
  isFinalScreenAccordian,
  setupData,
  key,
  downloadHandler,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e: any) => {
    if (onChange) onChange(e.target.checked);
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const _uniqid = uniqid();
  const downloadRef = useRef(null);

  return (
    // overflow-hidden
    <div
      className={clsx(
        "w-full border border-solid border-gray-ede rounded p-1 bg-white",
        className,
        {
          "!bg-surface-secondary": checked && !!isBackground,
          "!border-gray-eac": !checked,
        }
      )}
    >
      <input
        type={type}
        id={_uniqid}
        name={_uniqid}
        className="popup-accordion-input absolute opacity-0 -z-1"
        checked={isChecked}
        onChange={handleChange}
      />
      <label
        className={clsx(
          "tab-label flex justify-between bg-white cursor-pointer relative",
          labelClass,
          {
            "!border-b-0": !isChecked,
            "!bg-surface-secondary": checked && !!isBackground,
          }
        )}
        htmlFor={_uniqid}
      >
        {isFinalScreenAccordian ? (
          <div className="flex items-center w-full">
            {tabLabelChild}
            <button
              className="inline-flex ml-auto mr-10 text-blue-600 px-[12px] py-[6px] rounded-[4px] bg-blue-50 align-center gap-[8px] text-[14px] font-medium hover:bg-blue-100"
              type="button"
              onClick={downloadHandler}
            >
              <img src={DownloadIcon} alt="" />
              Download
            </button>
          </div>
        ) : (
          <div
            className={clsx("flex items-center justify-between w-full mr-10 overflow-hidden", {
              "mb-1": isChecked,
              "bg-surface-secondary": isChecked && !!isBackground,
            })}
          >
            <div className="flex items-center w-full overflow-hidden">
              {dragIcon && <img src={DragIcon} alt="drag icon" />}
              <div
                className={clsx("flex flex-col w-full", {
                  "!flex-row w-full justify-between items-center": count > 0,
                })}
              >
                <div
                  className={clsx(
                    "font-semibold text-gray-737 text-sm flex flex-row items-center",
                    labelChildClass
                  )}
                >
                  {tabLabelChild}
                  {count > 0 && (
                    <FiCheckCircle className="text-surface-default font-semibold ml-2" />
                  )}
                </div>
                {count > 0 && (
                  <div
                    className={clsx(
                      "w-5 h-5 flex justify-center items-center text-xs font-semibold text-black rounded-full",
                      {
                        "bg-white": isChecked,
                        "bg-surface-secondary": !isChecked,
                      }
                    )}
                  >
                    {count}
                  </div>
                )}
                {subLabel && <div className="ml-4">{subLabel}</div>}
              </div>
            </div>

            <div className="flex items-center">
              {verify && (
                <div className="flex items-center">
                  <VerifyIcon verified={true} />
                  <span className={`text-[10px] font-bold text-green-00d ml-1`}>
                    Auto-Identified & Verified
                  </span>
                </div>
              )}
              {removeHole && (
                <button
                  className="bg-transparent ml-2"
                  onClick={() => removeHole(Number(index))}
                >
                  <img src={TrashIcon} alt="trash icon" />
                </button>
              )}
            </div>
          </div>
        )}
      </label>
      <div
        className={clsx(
          "tab-content max-h-0 bg-white text-ellipsis whitespace-nowrap duration-150 overflow-auto",
          {
            "!bg-surface-secondary": isChecked && !!isBackground,
          },
          tabContentClassName
        )}
        ref={downloadRef}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
