import clsx from "clsx";
import { useState, useEffect, useRef } from "react";
import { RadioGroup, Radio } from "react-radio-input";
import uniqid from "uniqid";
import "./style.css";
import ConfirmFeatureSubTypeSwitchModal from "components/ConfirmFeatureSubTypeSwitchModal";

export interface IRadioOption {
  label: string;
  value: string | number | boolean;
  showChangeWarning?: boolean;
}

interface IRadioComponent {
  options: IRadioOption[];
  value?: string | number | boolean;
  setValue?: any;
  labelClassName?: string;
  disabled?: boolean;
  showChangeWarning?: boolean;
}

const normalizeBoolean = (value: any) => {
  if (value === "true") {
    return true;
  }
  if (value === "false") {
    return false;
  }
  return value;
};

const convertBoolean = (value: any) => {
  if (value === true) {
    return "true";
  }
  if (value === false) {
    return "false";
  }
  return value;
};

const RadioComponent: React.FC<IRadioComponent> = ({
  value,
  setValue,
  options,
  labelClassName,
  disabled = false,
  showChangeWarning,
}) => {
  const [selectedOption, setSelectedOption] = useState(convertBoolean(value));
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const eventRef = useRef<any>();

  const onChange = (e: any) => {
    eventRef.current = e;
    if (showChangeWarning) {
      setOpenConfirmModal(true);
      return;
    }
    setSelectedOption(e);
    const val = normalizeBoolean(e);
    setValue(val);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const name = uniqid();

  return (
    <>
      <div className="flex mr-1">
        <RadioGroup
          name={name}
          onChange={onChange}
          selectedValue={selectedOption}
        >
          {options?.map((option: IRadioOption, idx) => (
            <label
              htmlFor={`${name}-${option?.value}-id`}
              key={idx}
              className={clsx(
                `mr-2 flex items-center ${
                  disabled && selectedOption !== normalizeBoolean(option?.value)
                    ? "disabled-radio"
                    : "label-radio"
                }`,
                labelClassName
              )}
            >
              <Radio
                id={`${name}-${option?.value}-id`}
                value={`${convertBoolean(option?.value)}`}
                // @ts-ignore
                checked={selectedOption === normalizeBoolean(option?.value)}
                disabled={disabled}
              />
              <span
                className={`font-medium text-sm ml-[10px] ${
                  disabled && selectedOption !== normalizeBoolean(option?.value)
                    ? "text-gray-400"
                    : ""
                }`}
              >
                {option?.label}
              </span>
            </label>
          ))}
        </RadioGroup>
      </div>
      <ConfirmFeatureSubTypeSwitchModal
        isOpen={openConfirmModal}
        setIsOpen={setOpenConfirmModal}
        handleContinue={() => {
          setOpenConfirmModal(false);
          setSelectedOption(eventRef.current);
          const val = normalizeBoolean(eventRef.current);
          setValue(val);
        }}
      />
    </>
  );
};

export default RadioComponent;
