import { Box, Modal } from "@mui/material";
import Button from "components/Button";
import { IUnvisitedFeaturesModal, modalStyles } from "./utils";
import clsx from "clsx";

const UnvisitedFeaturesModal: React.FC<IUnvisitedFeaturesModal> = ({
  isOpen,
  setIsOpen,
  screen,
  featureList,
  handleContinue,
}) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Box sx={modalStyles}>
        <div className="max-w-[420px] rounded-[16px] bg-white">
          <div className="flex items-center justify-between p-[16px] border-b border-gray-300 gap-2">
            <div className="flex flex-col text-gray-700">
              <h2 className="text-[16px] font-medium">Are you sure?</h2>
              <p className="text-[10px]">
                Are you sure you want to proceed without choosing{" "}
                {screen === "strategy"
                  ? "Strategies"
                  : `Tools &
                Parameters`}{" "}
                for these features?
              </p>
            </div>
            <Button
              className="!bg-transparent !p-0 !rounded-0 !border-0 !shadow-none"
              onClick={() => setIsOpen(false)}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.63281 14.8692L10.002 10.5L14.3711 14.8692M14.3711 6.13086L10.0011 10.5L5.63281 6.13086"
                  stroke="#475467"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </div>
          <div className="text-left text-[14px] font-medium text-gray-600 w-[90%] mx-auto mt-4 mb-1">
            Features Name
          </div>
          <div className="rounded-[8px] border border-gray-200 m-auto h-auto w-[90%] mb-4">
            <div className="max-h-[30vh] overflow-y-auto rounded-[8px]">
              <table className="w-full border-collapse">
                <tbody>
                  {featureList.map((title, index) => (
                    <tr
                      key={`${title}`}
                      className={clsx("bg-white", {
                        "!bg-gray-50": index % 2 === 0,
                        "border-b border-gray-200":
                          index !== featureList?.length - 1,
                      })}
                    >
                      <td className="text-[14px] text-gray-700 px-2">
                        {title}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex border-t border-gray-200 p-[16px] justify-center gap-4">
            <Button
              className="modal-button"
              onClick={() => {
                setIsOpen(false);
                handleContinue();
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UnvisitedFeaturesModal;
