import clsx from "clsx";
import { useState, useEffect } from "react";
import "./style.css";
import uniqid from "uniqid";

interface ISquareCheckbox {
  checked: boolean;
  onChange: any;
  label?: string | React.ReactNode;
  type?: "black" | "yellow";
  className?: string;
  pending?: boolean;
  labelClassName?: string;
}

const SquareCheckbox: React.FC<ISquareCheckbox> = ({
  checked,
  onChange,
  label,
  type = "black",
  className,
  pending,
  labelClassName,
}) => {
  const [isCheck, setIsCheck] = useState(checked);

  const handleChange = (e: any) => {
    setIsCheck(e.target.checked);
    onChange(e.target.checked);
  };

  useEffect(() => {
    setIsCheck(checked);
  }, [checked]);

  const name = `check-${uniqid()}`;

  return (
    <div className="flex flex-row items-center">
      <div className="round-square w-full overflow-hidden">
        <input
          type="checkbox"
          id={name}
          checked={isCheck}
          onChange={handleChange}
        />
        <label
          htmlFor={name}
          className={clsx(
            "w-full overflow-hidden inline-flex",
            // {
            //   "border-2 border-black": type === "black",
            //   "border-2 border-surface-default": type === "yellow",
            //   pending: pending,
            // },
            className
          )}
        > <span className="checkmarkbox"></span>
          <span
          className={clsx("w-full overflow-hidden whitespace-nowrap text-black", {
            "left-[20px] top-[-1px] text-xs font-medium": !labelClassName,
            [`${labelClassName}`]: !!labelClassName,
          })}
        >
          {label}
        </span>
        </label>
        
      </div>
    </div>
  );
};

export default SquareCheckbox;
