import { getMachiningStrategy } from "services";
import { createDeepCopy } from "utils";
import { v4 as uuid } from "uuid";
import { assignSetup } from "../ManufacturingProcess/utils";

export async function fetchAndAddStrategyInfromation(
  feature: any,
  projectInfo: any
) {
  const response = await getMachiningStrategy([
    {
      ...feature.featureInformation?.feature_data,
      measurement_unit: projectInfo?.unit,
      cam_software: projectInfo?.cam_software ?? "siemens",
    },
  ]);
  if (!response?.data || response.status === false) return;

  const strategy = response?.strategies?.[0]?.machining_strategy;
  strategy?.forEach((strategy: any) => {
    strategy?.passes?.forEach((pass: any) => {
      pass?.operations?.forEach((operation: any) => {
        operation["isValid"] = true;
        operation["feedback"] = "";
      });
    });
  });
  response.data.strategies?.[0]?.machining_strategy?.forEach(
    (item: any, idx: number) => {
      if (idx === 0) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    }
  );
  feature.featureInformation.strategies = response.data.strategies;
}

export async function fetchStockToRemoveCalculations() {}

// export async function

function fetchSetupInformation(mappingForMachiningSetups: any): string {
  const setupId = Object.keys(mappingForMachiningSetups).find(
    (setupId: string) => mappingForMachiningSetups[setupId].index === 0
  );
  return setupId ?? "";
}

export function assignSetupsToOperations(this: any, feature: any) {
  let selectedStrategyIndex: number = -1;
  const setupId = fetchSetupInformation(this?.mappingForMachiningSetups);
  const selectedStrategy =
    feature.featureInformation?.strategies?.[0]?.machining_strategy?.find(
      (item: any, index: number) => {
        selectedStrategyIndex = index;
        return item?.isSelected;
      }
    );

  if (!selectedStrategy?.passes || !setupId) {
    return;
  }

  for (const [passIndex, pass] of selectedStrategy.passes.entries()) {
    if (!pass?.operations) continue;
    for (const [operationIndex, operation] of pass.operations.entries()) {
      if (!operation) continue;
      assignSetup(
        Number(operationIndex),
        Number(passIndex),
        Number(selectedStrategyIndex),
        feature.feature_id,
        this?.assignedMachiningSetups,
        this?.updatedSetups,
        setupId,
        this?.mappingForMachiningSetups
      );
    }
  }
}
