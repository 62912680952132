import { useContext, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  HOLE_BOTTOM_TYPE_OPTIONS,
  HOLE_BOTTOM_OPTIONS,
  CONFIRM_OPTIONS,
  SET_MACHINE_SETUP,
  HOLE_SURFACE_TYPE_OPTIONS,
  THREAD_HOLE_VALIDATION,
  POSITIVE_VALIDATION,
  BOTTOM_ANGLE_VALIDATION,
  QUANTITY_VALIDATION,
  decimalRegExp,
  FINISH_TYPE_OPTIONS,
  THREAD_ANGLE_VALIDATION,
  negativeDecimalRegExp,
  HOLE_TYPE_OPTIONS,
  onlyNumberRegexExp,
} from "constant";
import { GlobalContext } from "context/GlobalContext";
import Select from "components/Select";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipContent from "../../FeatureToolTipContent";
import TooltipV2 from "components/ToolTipV2";
import { roundOff } from "utils";

interface IThreadHole {
  feature_id?: string;
  feature_info?: any;
  setAllFieldsTouched?: boolean;
}

const init_thread_hole = {
  thread_size: "",
  minor_diameter: "",
  thread_pitch: "",
  thread_depth: "",
  surface_type: "",
  depth: "",
  sequential: "",
  bottom_type: "",
  bottom: "",
  bottom_radius: "",
  bottom_angle: "",
  thread_angle: "",
  deburring: "",
  quantity: "",
  hole_type: "",
  stock_to_remove: "",
  vertical_clearance: "",
  horizontal_clearance: "",
  finish_type: "",
  upper_tolerance: "",
  lower_tolerance: "",
};

const ThreadHole: React.FC<IThreadHole> = ({
  feature_info,
  feature_id,
  setAllFieldsTouched,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { projectInfo, setupNumber, machiningSetups } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        thread_size: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        thread_depth: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        minor_diameter: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        thread_pitch: Yup.number()
          .required("This field is required")
          .min(
            THREAD_HOLE_VALIDATION.thread_pitch.min[projectInfo?.unit || "mm"],
            `Value must be atleast ${
              THREAD_HOLE_VALIDATION.thread_pitch.min[projectInfo?.unit || "mm"]
            }`
          )
          .max(
            THREAD_HOLE_VALIDATION.thread_pitch.max[projectInfo?.unit || "mm"],
            `Value must be ${
              THREAD_HOLE_VALIDATION.thread_pitch.max[projectInfo?.unit || "mm"]
            } or less`
          ),
        quantity: QUANTITY_VALIDATION,
        stock_to_remove: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        depth: Yup.number()
          .required("This field is required")
          .when("thread_depth", (thread_depth, schema) => {
            return thread_depth
              ? schema.min(
                  thread_depth,
                  `Value must be atleast ${thread_depth}`
                )
              : schema;
          }),
        bottom_radius: Yup.number()
          .required("This field is required")
          .min(0, "Value must be atleast 0")
          .when("thread_size", (minor_diameter, schema) => {
            return minor_diameter
              ? schema.max(
                  roundOff(minor_diameter / 2),
                  `Value must be ${roundOff(minor_diameter / 2)} or less`
                )
              : schema;
          }),
        bottom_angle: BOTTOM_ANGLE_VALIDATION,
        thread_angle: THREAD_ANGLE_VALIDATION,
        finish_type: Yup.string()
          .oneOf([...FINISH_TYPE_OPTIONS.map((option: any) => option.value)])
          .required("This field is required"),
        upper_tolerance: Yup.number()
          .moreThan(0, "Value must be greater than 0")
          .required("This field is required"),
        lower_tolerance: Yup.number()
          .required("This field is required")
          .typeError("Please enter a valid number")
          .when("upper_tolerance", (upper_tolerance, schema) => {
            return upper_tolerance
              ? schema.lessThan(
                  upper_tolerance,
                  `Value must be less than ${upper_tolerance}`
                )
              : schema;
          }),
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    let formValues: any = {};
    Object.keys(init_thread_hole).forEach((key: string) => {
      switch (true) {
        case key === "deburring":
        case key === "sequential":
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ? feature_info?.[key] === "yes" : "",
          };
          break;
        case key === "finish_type" &&
          ("upper_tolerance" in feature_info ||
            "lower_tolerance" in feature_info ||
            values?.finish_type === "custom"):
          formValues = {
            ...formValues,
            [key]: "custom",
          };
          break;
        default:
          formValues = {
            ...formValues,
            [key]: feature_info?.[key] ?? "",
          };
      }
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_thread_hole,
    validationSchema,
    onSubmit: () => {},
  });

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "bottom_type":
        if (value === "through") {
          delete updated_feature["bottom"];
          setFieldValue("bottom", "");
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom":
        if (value === "flat") {
          delete updated_feature["bottom_angle"];
          setFieldValue("bottom_angle", "");
        } else if (value === "v shape") {
          delete updated_feature["bottom_radius"];
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "sequential":
      case "deburring":
        updated_feature = {
          ...updated_feature,
          [key]: value ? "yes" : "no",
        };
        break;
      case "surface_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "finish_type":
        if (value !== "custom") {
          delete updated_feature.upper_tolerance;
          delete updated_feature.lower_tolerance;
          setFieldValue("upper_tolerance", "");
          setFieldValue("lower_tolerance", "");
          updated_feature = {
            ...updated_feature,
            [key]: value,
          };
        } else delete updated_feature.finish_type;
        break;
      case "hole_type":
        if (value === "solid") {
          delete updated_feature.stock_to_remove;
          setFieldValue("stock_to_remove", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }
    if (flag) setFieldValue(key, value);

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_thread_hole).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div className="relative">
      <div>
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Thread Size"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="thread_size"
              value={values?.thread_size}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("thread_size", values?.thread_size);
              }}
              infoTooltipTitle={TooltipContent("thread_hole", "threadSize")}
              invalid={touched.thread_size && errors.thread_size}
              helperText={touched.thread_size && errors.thread_size}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Minor Diameter"
              className=""
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="minor_diameter"
              value={values?.minor_diameter}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("minor_diameter", values?.minor_diameter);
              }}
              infoTooltipTitle={TooltipContent("thread_hole", "minorDiameter")}
              invalid={touched.minor_diameter && errors.minor_diameter}
              helperText={touched.minor_diameter && errors.minor_diameter}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="w-6/12">
          <Input
            handleFocus={handleBlur}
            placeholder="Thread Pitch"
            className="mr-2"
            type="text"
            unit={projectInfo?.unit || "mm"}
            name="thread_pitch"
            value={values?.thread_pitch}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("thread_pitch", values?.thread_pitch);
            }}
            infoTooltipTitle={TooltipContent("thread_hole", "threadPitch")}
            invalid={touched.thread_pitch && errors.thread_pitch}
            helperText={touched.thread_pitch && errors.thread_pitch}
            regex={decimalRegExp}
          />
        </div>
        <div className="w-6/12">
          <Input
            handleFocus={handleBlur}
            placeholder="Thread Depth"
            className=""
            type="text"
            unit={projectInfo?.unit || "mm"}
            name="thread_depth"
            value={values?.thread_depth}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("thread_depth", values?.thread_depth);
            }}
            infoTooltipTitle={TooltipContent("thread_hole", "threadDepth")}
            invalid={touched.thread_depth && errors.thread_depth}
            helperText={touched.thread_depth && errors.thread_depth}
            regex={decimalRegExp}
          />
        </div>
      </div>

      <div className="flex flex-row mt-4">
        <div className="w-6/12">
          <Input
            handleFocus={handleBlur}
            placeholder="Depth"
            className="mr-2"
            type="text"
            unit={projectInfo?.unit || "mm"}
            name="depth"
            value={values?.depth}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("depth", values?.depth);
            }}
            infoTooltipTitle={TooltipContent("thread_hole", "holeDepth")}
            invalid={touched.depth && errors.depth}
            helperText={touched.depth && errors.depth}
            regex={decimalRegExp}
          />
        </div>
        <div className="w-6/12">
          <Input
            handleFocus={handleBlur}
            placeholder="Thread Angle"
            className=""
            type="text"
            name="thread_angle"
            value={values?.thread_angle}
            onChange={handleChange}
            onBlur={(e: any) => {
              handleBlur(e);
              handleChangeFunc("thread_angle", values?.thread_angle);
            }}
            infoTooltipTitle={TooltipContent("thread_hole", "threadAngle")}
            invalid={touched.thread_angle && errors.thread_angle}
            helperText={touched.thread_angle && errors.thread_angle}
            regex={onlyNumberRegexExp}
          />
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Bottom Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Bottom Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("thread_hole", "bottomType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("bottom_type", val, true)
              }
              value={values?.bottom_type}
            />
          </div>
          {values?.bottom_type === "blind" && (
            <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
              <div className="flex align-baseline mr-auto">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Bottom
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("thread_hole", "bottom")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={HOLE_BOTTOM_OPTIONS}
                setValue={(val: any) => handleChangeFunc("bottom", val, true)}
                value={values?.bottom}
              />
            </div>
          )}
        </div>

        {values?.bottom_type === "blind" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              {values?.bottom === "flat" && (
                <Input
                  handleFocus={handleBlur}
                  placeholder="Bottom Radius"
                  type="text"
                  className="mr-2"
                  unit={projectInfo?.unit || "mm"}
                  name="bottom_radius"
                  value={values?.bottom_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_radius", values?.bottom_radius);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "thread_hole",
                    "bottomRadius"
                  )}
                  invalid={touched.bottom_radius && errors.bottom_radius}
                  helperText={touched.bottom_radius && errors.bottom_radius}
                  regex={decimalRegExp}
                />
              )}
              {values?.bottom === "v shape" && (
                <Input
                  handleFocus={handleBlur}
                  placeholder="Bottom Angle"
                  type="text"
                  className="mr-2"
                  name="bottom_angle"
                  value={values?.bottom_angle}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("bottom_angle", values?.bottom_angle);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "thread_hole",
                    "bottomAngle"
                  )}
                  invalid={touched.bottom_angle && errors.bottom_angle}
                  helperText={touched.bottom_angle && errors.bottom_angle}
                  regex={decimalRegExp}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Deburring
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("thread_hole", "deburring")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("deburring", val, true)}
              value={values?.deburring}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className=""
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", e?.target?.value);
              }}
              infoTooltipTitle={TooltipContent("thread_hole", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Surface Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("thread_hole", "surfaceType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_SURFACE_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("surface_type", val, true)
              }
              value={values?.surface_type}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Sequential
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("thread_hole", "sequential")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={CONFIRM_OPTIONS}
              setValue={(val: any) => handleChangeFunc("sequential", val, true)}
              value={values?.sequential}
            />
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Hole Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("thread_hole", "holeType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={HOLE_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("hole_type", val, true)}
              value={values?.hole_type}
            />
          </div>
          {values?.hole_type === "hollow" && (
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Stock to Remove"
                className=""
                type="text"
                name="stock_to_remove"
                unit={projectInfo?.unit || "mm"}
                value={values?.stock_to_remove}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("stock_to_remove", values?.stock_to_remove);
                }}
                infoTooltipTitle={TooltipContent(
                  "thread_hole",
                  "stockToRemove"
                )}
                invalid={touched.stock_to_remove && errors.stock_to_remove}
                helperText={touched.stock_to_remove && errors.stock_to_remove}
                regex={decimalRegExp}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Tolerance Inputs
        </p>
        {projectInfo?.unit === "in" ? (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Upper tolerance"
                type="text"
                className="mr-2"
                name="upper_tolerance"
                unit={projectInfo?.unit}
                value={values?.upper_tolerance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("upper_tolerance", e?.target?.value);
                }}
                infoTooltipTitle={TooltipContent(
                  "thread_hole",
                  "upperTolerance"
                )}
                invalid={touched.upper_tolerance && errors.upper_tolerance}
                helperText={touched.upper_tolerance && errors.upper_tolerance}
                regex={decimalRegExp}
              />
            </div>
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Lower tolerance"
                type="text"
                className=""
                name="lower_tolerance"
                unit={projectInfo?.unit}
                value={values?.lower_tolerance}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("lower_tolerance", e?.target?.value);
                }}
                infoTooltipTitle={TooltipContent(
                  "thread_hole",
                  "lowerTolerance"
                )}
                invalid={touched.lower_tolerance && errors.lower_tolerance}
                helperText={touched.lower_tolerance && errors.lower_tolerance}
                regex={negativeDecimalRegExp}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row mt-4">
              <div className="flex w-6/12">
                <Select
                  options={FINISH_TYPE_OPTIONS}
                  placeholder="Finish Type"
                  className="w-full mr-2"
                  value={values?.finish_type}
                  onChange={(val: any) =>
                    handleChangeFunc("finish_type", val, true)
                  }
                  handleClose={(value: string) => {
                    if (!value) setFieldTouched("finish_type", true);
                  }}
                  infoTooltipTitle={TooltipContent("thread_hole", "finishType")}
                  invalid={touched.finish_type && errors.finish_type}
                  helperText={touched.finish_type && errors.finish_type}
                  onBlur={handleBlur}
                />
              </div>
              {values?.finish_type === "custom" && (
                <div className="w-6/12">
                  <Input
                    handleFocus={handleBlur}
                    placeholder="Upper tolerance"
                    type="text"
                    className=""
                    name="upper_tolerance"
                    unit={projectInfo?.unit}
                    value={values?.upper_tolerance}
                    onChange={handleChange}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      handleChangeFunc("upper_tolerance", e?.target?.value);
                    }}
                    infoTooltipTitle={TooltipContent(
                      "thread_hole",
                      "upperTolerance"
                    )}
                    invalid={touched.upper_tolerance && errors.upper_tolerance}
                    helperText={
                      touched.upper_tolerance && errors.upper_tolerance
                    }
                    regex={decimalRegExp}
                  />
                </div>
              )}
            </div>
            {values?.finish_type === "custom" && (
              <div className="w-6/12 mt-4">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Lower tolerance"
                  type="text"
                  className="mr-2"
                  name="lower_tolerance"
                  unit={projectInfo?.unit}
                  value={values?.lower_tolerance}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("lower_tolerance", e?.target?.value);
                  }}
                  infoTooltipTitle={TooltipContent(
                    "thread_hole",
                    "lowerTolerance"
                  )}
                  invalid={touched.lower_tolerance && errors.lower_tolerance}
                  helperText={touched.lower_tolerance && errors.lower_tolerance}
                  regex={negativeDecimalRegExp}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "thread_hole",
                "verticalClearance"
              )}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "thread_hole",
                "horizontalClearance"
              )}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadHole;
