import { useCallback, useContext, useEffect, useState } from "react";
import DefaultLayout from "Layouts/DefaultLayout";
import SearchInput from "components/SearchInput";
import Button from "components/Button";
import { useLocation, useNavigate } from "react-router-dom";
// import { useLocalFusionApiCall } from "hooks/useLocalFusionAPI";
import { GlobalContext } from "context/GlobalContext";
import {
  SET_LOADING_STYLE,
  SET_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_VISITED_FEATURES,
} from "constant";
import { useApiCall, useLocalSiemensNxApiCall } from "hooks";
import { getProjectsAPI } from "services";
import Table from "components/Table";
import StyledProjectValue from "./StyledProjectValue";
import { FiEdit2, FiEye } from "react-icons/fi";
import { TbCopyPlus } from "react-icons/tb";
import LoadingBar from "components/LoadingBar";
import CloneProjectModal from "components/CloneProjectModal";
import { createDeepCopy, projectComparator } from "utils";
import { toast } from "react-toastify";
import CloneSuccess from "assets/images/clone-success.svg";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

const PROJECT_INIT_COLUMNS = [
  { label: "Name", id: "projectName" },
  { label: "Material", id: "material" },
  { label: "CAD Part ID", id: "cadPartId" },
  { label: "Machine", id: "machineName" },
  { label: "Created", id: "createdAt" },
];

type Order = "asc" | "desc";

const ProjectList = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const { userInfo } = state;
  const location = useLocation();
  const [fetchProjects, loading, projects] = useApiCall(getProjectsAPI);
  const [searchKey, setSearchKey] = useState<string>("");
  const [projectToClone, setProjectToClone] = useState<any>({});
  const [openCloneProjectModal, setOpenCloneProjectModal] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<Order>("desc");
  const [fetchSiemensNxData] = useLocalSiemensNxApiCall();

  const sortProjects = useCallback(
    (field: string) => {
      if (!projects) return;

      const projectsCopy: any = [...projects];
      const isSortingBySameField = orderBy === field;

      if (isSortingBySameField && order === "desc") {
        setOrderBy("");
        setProjectList(
          projectsCopy.sort(projectComparator("createdAt", "desc"))
        );
        return;
      }

      const newOrder = isSortingBySameField && order === "asc" ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(field);
      setProjectList(projectsCopy.sort(projectComparator(field, newOrder)));
    },
    [projects, order, orderBy]
  );

  useEffect(() => {
    if (!userInfo) return;
    const payload = {
      organizationId: userInfo?.["custom:organizationId"],
      userRecordId: userInfo?.["sub"],
    };
    fetchProjects(payload);
    if (location.state?.projectCloneCreated) {
      toast(
        <div className="flex flex-row gap-1 items-baseline">
          <img src={CloneSuccess} />
          <div>Project has been created successfully</div>
        </div>,
        {
          className: "!bg-grean-039 !rounded-lg",
          bodyClassName: "text-white text-[16px]",
          position: "bottom-right",
        }
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (!projects) return;
    const projectsCopy: any = [...projects];
    projectsCopy.sort(projectComparator("createdAt", "desc"));
    setProjectList(projectsCopy);
  }, [loading]);

  // Removing Edit Project Option from Project List
  // const handleEditSelect = (e: any, project: any) => {
  //   e.stopPropagation();
  //   dispatch({
  //     type: SET_PROJECT_INFO,
  //     payload: project,
  //   });
  //   navigate(`/project?tab=project&id=${project?.ncProjectId}&isEdit=true`);
  // };

  const handleViewSelect = (e: any, project: any) => {
    e.stopPropagation();
    dispatch({
      type: SET_PROJECT_INFO,
      payload: project,
    });
    navigate(`/project?tab=project&id=${project?.ncProjectId}`);
  };

  const handleCloneProject = (e: any, project: any) => {
    e.stopPropagation();
    let projectToClone: any = createDeepCopy(project);
    delete projectToClone.createdAt;
    delete projectToClone.ncProjectId;
    delete projectToClone.organizationId;
    delete projectToClone.userRecordId;
    delete projectToClone.updated_timestamp;
    setProjectToClone(projectToClone);
    setOpenCloneProjectModal(true);
  };

  const handleSelect = (project: any) => {
    dispatch({
      type: SET_LOADING_STYLE,
      payload: "!bg-gray-200 !bg-opacity-100",
    });
    dispatch({
      type: SET_PROJECT_INFO,
      payload: project,
    });
    dispatch({
      type: SET_MACHINE_SETUP,
      payload: [],
    });
    dispatch({
      type: SET_VISITED_FEATURES,
      payload: {
        strategy: new Set<string>([]),
        toolsAndParams: new Set<string>([]),
      },
    });
    /**
     * When ever user opens a new project reset any toolpath previews
     * that might be present.
     * Currently we treat it as a fire and forget trigger, so no need
     * for error handling.
     * This is currently only enabled for siemens cam env.
     */
    if (project.cam_software === "siemens") {
      try {
        fetchSiemensNxData("reset_tool_paths", {});
      } catch {}
    }
    navigate("/project?tab=feature", { state: { load: true } });
  };

  return (
    <DefaultLayout>
      <div className="flex flex-row justify-between items-center mt-2 mx-4 my-4">
        <span className="font-semibold text-xl">Projects</span>
        <div className="flex flex-row items-center">
          <SearchInput
            value=""
            onChange={(e: any) => setSearchKey(e?.target?.value)}
            placeholder="Search Project"
            className="mr-2"
          />
          <Button onClick={() => navigate("/project?isEdit=true")}>
            Add Project
          </Button>
        </div>
      </div>
      {!!loading && <LoadingBar />}
      <div className="mx-4 my-4 overflow-y-auto relative">
        <Table className="w-full">
          <TableHead
            className="!h-10 bg-surface-default sticky top-0 left-0"
            sx={{
              borderRadius: "0.5rem !important",
            }}
          >
            <TableRow>
              {PROJECT_INIT_COLUMNS.map((column: any, idx: number) => (
                <TableCell
                  key={idx}
                  className="py-0"
                  sx={{
                    ...(idx === 0 && {
                      borderRadius: "0.5rem 0 0 0.5rem",
                    }),
                  }}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    sx={{
                      fontWeight: "500",
                      fontSize: "0.875rem",
                      color: "white !important",
                      "&:hover, &.Mui-active, & .MuiTableSortLabel-icon": {
                        color: "white",
                      },
                      "&.Mui-active .MuiTableSortLabel-icon": {
                        color: "white",
                      },
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => sortProjects(column.id)}
                  >
                    {column?.label}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                className="py-0 text-white"
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "500",
                  borderRadius: "0 0.5rem 0.5rem 0",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <Table.Tbody>
            {!!projectList?.length &&
              projectList?.map((project: any, idx: number) => {
                if (
                  project?.projectName
                    ?.toLowerCase()
                    .includes(searchKey.toLowerCase())
                ) {
                  return (
                    <Table.Tr
                      className="cursor-pointer"
                      onClick={() => handleSelect(project)}
                    >
                      {PROJECT_INIT_COLUMNS?.map((column: any, idx: number) => (
                        <Table.Td key={idx}>
                          <StyledProjectValue
                            project={project}
                            valueKey={column?.id}
                          />
                        </Table.Td>
                      ))}
                      <Table.Td>
                        <div className="flex flex-row items-center space-x-4">
                          {/* Removing Edit Project Option from Project List
                           <Button
                            variant="ghost"
                            className="px-0 !py-0"
                            onClick={(e: any) => handleEditSelect(e, project)}
                          >
                            <FiEdit2 className="w-5 h-5 text-black" />
                          </Button> */}
                          <Button
                            variant="ghost"
                            className="px-0 !py-0"
                            onClick={(e: any) => handleViewSelect(e, project)}
                          >
                            <FiEye className="w-5 h-5 text-black" />
                          </Button>
                          <Button
                            variant="ghost"
                            className="px-0 !py-0"
                            onClick={(e: any) => handleCloneProject(e, project)}
                          >
                            <TbCopyPlus className="w-5 h-5 text-black" />
                          </Button>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  );
                }
                return null;
              })}
          </Table.Tbody>
        </Table>
        <CloneProjectModal
          isOpen={openCloneProjectModal}
          setIsOpen={setOpenCloneProjectModal}
          projectToClone={projectToClone}
          projectNameSet={
            new Set(
              projects?.map((project: any) => project.projectName?.trim())
            )
          }
        />
      </div>
    </DefaultLayout>
  );
};

export default ProjectList;
