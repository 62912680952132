import { Dispatch, SetStateAction } from "react";

export interface IRemindSetupAssignmentModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "440px",
  boxShadow: "none",
  outline: "none",
  width: "100%",
};
