import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SummaryImg from "assets/images/summary.svg";
import SquareCheckbox from "components/SquareCheckbox";
import AccordionItem from "components/AccordionItem";
import FeatureSummaryItem from "./FeatureSummaryItem";
import Button from "components/Button";
import { GlobalContext } from "context/GlobalContext";
import {
  keyList,
  makeLabels,
  SET_LOADING_BAR,
  SET_ORIGIN_MACHINE_SETUP,
  SET_PROJECT_INFO,
  SET_STREAM_STEP,
  SET_VISIBLE_IDS,
  SET_VISITED_FEATURES,
} from "constant";
import { useApiCall, useLocalSiemensNxApiCall } from "hooks";
import {
  postCreateUserSelectionAPI,
  putProjectAPI,
  putUserSelectionAPI,
} from "services";
import { extractSelectedDataFromMachiningSetups } from "utils";
import DefaultLayout from "Layouts/DefaultLayout";
import { ConfirmDefaultRecommendationsSelection } from "./ConfirmDefaultRecommendationsSelection";

const initData = [
  {
    category: "Hole",
    checked: false,
    pending: false,
    data: [
      {
        title: "Hole 1",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
      {
        title: "Hole 2",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
    ],
  },
  {
    category: "Thread Hole",
    checked: false,
    pending: false,
    data: [
      {
        title: "Thread Hole 1",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
      {
        title: "Thread Hole 2",
        checked: false,
        pending: false,
        data: [
          { key: "Diameter", value: "****" },
          { key: "Depth", value: "****" },
          { key: "Type", value: "****" },
          { key: "Bottom", value: "****" },
          { key: "Deburring", value: "Yes" },
          { key: "No. of Holes", value: "****" },
          { key: "Distance from Top", value: "****" },
          { key: "Use Extension rod", value: "****" },
          { key: "Add Nearest wall distances", value: "****" },
          { key: "Nearest distance X-direction", value: "****" },
          { key: "Nearest distance Y-direction", value: "****" },
          { key: "Extension rod length", value: "****" },
          { key: "Roughing tool", value: "****" },
          { key: "Corner radius", value: "****" },
          { key: "Countersink angle", value: "****" },
          { key: "Drill/Tip angle", value: "****" },
        ],
      },
    ],
  },
];

const FeatureSummary = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);
  const {
    projectInfo,
    setupNumber,
    visitedFeatures,
    machiningSetups,
    originMachiningSetups,
    visiblefeature_ids,
    mappingForMachiningSetups,
    assignedMachiningSetups,
  } = state;
  const [data, setData] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [visibleFeatures, setVisibleFeatures] = useState<any>([]);
  const [checkedAll, setCheckedAll] = useState(true);
  const [pendingAll, setPendingAll] = useState(false);
  const [putUserSelection]: any = useApiCall(putUserSelectionAPI);
  const [postCreateUserSelection]: any = useApiCall(postCreateUserSelectionAPI);
  const [putProject]: any = useApiCall(putProjectAPI);
  const [fetchSiemensNxData] = useLocalSiemensNxApiCall();
  const [defaultRecommendationCheck, setDefaultRecommendationCheck] =
    useState(false);
  const location = useLocation();
  const initialVisibleFeaturesSet = new Set(visiblefeature_ids?.[1]);
  useEffect(() => {
    if (!projectInfo || !Object.keys(projectInfo)?.length) {
      navigate("/projects");
      return;
    }
    let arr: any[] = [];

    const setupIdx = machiningSetups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );
    // machiningSetups?.[setupIdx]?.features
    // const availableData = keyList.filter(({ key }) => state[key].length > 0);
    let pendingCount = 0;
    keyList?.forEach(({ title, type }) => {
      const existing_features = machiningSetups?.[setupIdx]?.features?.filter(
        (feature: any) =>
          feature?.featureInformation?.feature_data?.feature_type === type
      );
      if (!existing_features?.length) return;
      let detailData: any[] = [];
      let selectedFeatures = 0;
      existing_features?.forEach((feature: any, idx1: number) => {
        let subData: any[] = [];
        let deepData: any[] = [];
        const labelsArray = makeLabels(
          feature?.featureInformation?.feature_data?.feature_name,
          {
            ...feature?.featureInformation?.feature_data?.feature_info,
          }
        );

        labelsArray?.forEach((summary: any, idx2: number) => {
          subData.push({
            key: summary?.label,
            inputKey: summary?.key,
            value:
              feature?.featureInformation?.feature_data?.feature_info?.[
                summary?.key
              ],
          });
        });
        const isFeatureChecked =
          location.state?.referrer === "feature" ||
          initialVisibleFeaturesSet.has(feature?.feature_id);
        if (isFeatureChecked) selectedFeatures++;
        detailData.push({
          title: `${title} ${idx1 + 1}`,
          checked: isFeatureChecked,
          pending: false,
          index: idx1,
          data: subData,
          deepData: deepData,
          feature_id: feature?.feature_id,
          tag_id: feature.featureInformation?.tag_id,
          camSpecificNames: feature.featureInformation?.cam_specific_names,
        });
      });
      if (!selectedFeatures || selectedFeatures < existing_features?.length)
        pendingCount++;
      arr.push({
        category: title,
        checked: selectedFeatures,
        pending: selectedFeatures < existing_features?.length,
        // set_type: set_type,
        data: detailData,
      });
    });

    setCheckedAll(pendingCount < arr.length);
    setPendingAll(pendingCount > 0 && pendingCount < arr.length);
    setData(arr);

    // let arr: any[] = [];
    // availableData?.forEach(({ key, title, type, set_type }, idx) => {
    //   let detailData: any[] = [];
    //   state[key]?.forEach((item: any, idx1: number) => {
    //     const subType = item?.type ? item?.type : type;
    //     let subData: any[] = [];
    //     let deepData: any[] = [];
    //     if (type === "core_cavity") {
    //       item?.data?.forEach((deep: any, idx3: number) => {
    //         let deepSubData: any[] = [];
    //         SUMMARY_VIEW[type]?.["data"]?.forEach(
    //           (summary: any, idx2: number) => {
    //             deepSubData.push({
    //               key: summary?.label,
    //               value: deep[summary?.key],
    //             });
    //           }
    //         );
    //         deepData.push(deepSubData);
    //       });
    //     }
    //     SUMMARY_VIEW[type][subType]?.forEach((summary: any, idx2: number) => {
    //       subData.push({ key: summary?.label, value: item[summary?.key] });
    //     });
    //     detailData.push({
    //       title: `${title} ${idx1 + 1}`,
    //       checked: true,
    //       pending: false,
    //       index: idx1,
    //       data: subData,
    //       deepData: deepData,
    //     });
    //   });

    //   arr.push({
    //     category: title,
    //     checked: true,
    //     pending: false,
    //     set_type: set_type,
    //     data: detailData,
    //   });
    // });
    // setData(arr);
  }, [setupNumber, machiningSetups]);

  useEffect(() => {
    const visibleFeaturesList: any = [];
    data?.forEach((categoryData: any) => {
      if (categoryData?.checked) {
        categoryData?.data?.forEach((item: any) => {
          if (item.checked) {
            visibleFeaturesList.push(item?.feature_id);
          }
        });
      }
    });
    setVisibleFeatures(visibleFeaturesList);
  }, [data]);

  const handleSubChecked = (idx: number, subIdx: number, checkVal: boolean) => {
    const newData = [...data];
    newData[idx].data[subIdx].checked = checkVal;
    const [checked, pending] = isChecked(newData[idx].data);
    newData[idx].checked = checked;
    newData[idx].pending = pending;
    const [rootChecked, rootPending] = isChecked(newData, true);
    setCheckedAll(rootChecked);
    setPendingAll(rootPending);
    setData(newData);
  };

  const isChecked = (checkingData: any[], rootFlag = false): any => {
    let pendingData = [];
    if (!rootFlag) pendingData = checkingData.filter((item) => !item.checked);
    else
      pendingData = checkingData.filter(
        (item) => !item.checked || item.pending
      );
    if (pendingData.length === 0) return [true, false];
    else if (pendingData && pendingData.length < checkingData.length)
      return [true, true];
    else if (pendingData && pendingData.length === checkingData.length)
      return [false, false];
  };

  const handleChecked = (idx: number, checkVal: boolean) => {
    if (idx > -1) {
      let newData = [...data];
      newData[idx].checked = checkVal;
      newData[idx].pending = false;
      newData[idx].data?.forEach((item: any) => {
        item.checked = checkVal;
      });
      setData(newData);
      const [rootChecked, rootPending] = isChecked(newData, true);
      setCheckedAll(rootChecked);
      setPendingAll(rootPending);
    } else {
      let newData = [...data];
      newData.forEach((rootItem: any, idx) => {
        rootItem.checked = checkVal;
        rootItem.pending = false;
        rootItem.data?.forEach((item: any) => {
          item.checked = checkVal;
        });
      });
      setData(newData);
      setCheckedAll(checkVal);
      setPendingAll(false);
    }
  };

  const handleNext = (tab: string, executionMode?: number) => {
    dispatch({
      type: SET_VISIBLE_IDS,
      payload: {
        pageMapping: defaultRecommendationCheck ? "0" : "1",
        ids: visibleFeatures,
      },
    });
    const payload: any = {
      organizationId: projectInfo?.organizationId,
      streamStep: "initial-trigger",
      selectionId: projectInfo?.userSelectionId,
      ncProjectId: projectInfo?.ncProjectId,
      userRecordId: projectInfo?.userRecordId,
      machineId: "",
      machineType: "",
      workpieceCADfile: "",
      workpieceId: "",
      workpiecePathToCADfile: "",
      workpieceMaterial: projectInfo?.material,
      workpieceSubMaterial: projectInfo?.subMaterial,
      workpieceRawDimensions: {
        length: "",
        width: "",
        height: "",
      },
      machiningSetups,
      mappingForMachiningSetups,
      assignedMachiningSetups,
    };
    if (projectInfo?.userSelectionId) {
      if (
        JSON.stringify(machiningSetups) ===
        JSON.stringify(originMachiningSetups)
      ) {
        setTimeout(() => {
          navigate(`/project?tab=${tab}`, {
            ...(tab === "default-recommendations" && {
              state: { referrer: "feature-summary", executionMode },
            }),
          });
        }, 500);
      } else {
        /**
         * If there is a change deteched in the machining setups we reset any
         * toolpath preview that user may have created.
         * Currently we treat it as a fire and forget trigger, so no need
         * for error handling.
         * This is currently only enabled for siemens cam env.
         */
        if (projectInfo.cam_software === "siemens") {
          try {
            fetchSiemensNxData("reset_tool_paths", {});
          } catch {}
        }
        // Identifying the features where feature_data is changed and removing
        // strategy data from them.
        machiningSetups?.forEach((setupInfo: any, index: number) => {
          const originalSetupInfo = originMachiningSetups?.[index];
          const strategyVisitedFeatures = visitedFeatures.strategy;
          const toolsVisitedFeatures = visitedFeatures.toolsAndParams;
          for (const feature of setupInfo?.features ?? []) {
            for (const originalFeature of originalSetupInfo?.features ?? []) {
              if (
                feature?.feature_id === originalFeature?.feature_id &&
                JSON.stringify(feature?.featureInformation?.feature_data) !==
                  JSON.stringify(
                    originalFeature?.featureInformation?.feature_data
                  )
              ) {
                delete feature?.featureInformation?.strategies;
                strategyVisitedFeatures.delete(feature?.feature_id);
                toolsVisitedFeatures.delete(feature?.feature_id);
              }
            }
          }
          dispatch({
            type: SET_VISITED_FEATURES,
            payload: {
              strategy: strategyVisitedFeatures,
              toolsAndParams: toolsVisitedFeatures,
            },
          });
        });
        // ---------------------------------------------------------------------
        // IMTS CHANGE
        const newMachiningSetups =
          extractSelectedDataFromMachiningSetups(machiningSetups);
        payload.machiningSetups = newMachiningSetups;
        // ---------------------------------------------------------------------
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        putUserSelection(payload)
          .then(() => {
            dispatch({
              type: SET_ORIGIN_MACHINE_SETUP,
              payload: machiningSetups,
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({
                type: SET_LOADING_BAR,
                payload: false,
              });
              navigate(`/project?tab=${tab}`, {
                ...(tab === "default-recommendations" && {
                  state: { referrer: "feature-summary", executionMode },
                }),
              });
            }, 500);
          });
      }
    } else {
      dispatch({
        type: SET_LOADING_BAR,
        payload: true,
      });
      // -----------------------------------------------------------------------
      // IMTS CHANGE
      const newMachiningSetups =
        extractSelectedDataFromMachiningSetups(machiningSetups);
      payload.machiningSetups = newMachiningSetups;
      // -----------------------------------------------------------------------
      postCreateUserSelection(payload)
        .then(async (res: any) => {
          const putProjectPayload = {
            organizationId: projectInfo?.organizationId,
            ncProjectId: projectInfo?.ncProjectId,
            userRecordId: projectInfo?.userRecordId,
            userSelectionId: res?.selectionId,
          };
          await putProject(putProjectPayload);
          dispatch({
            type: SET_PROJECT_INFO,
            payload: {
              ...projectInfo,
              userSelectionId: res?.selectionId,
            },
          });
          dispatch({
            type: SET_STREAM_STEP,
            payload: "intial-trigger",
          });
          dispatch({
            type: SET_ORIGIN_MACHINE_SETUP,
            payload: [...machiningSetups],
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({
              type: SET_LOADING_BAR,
              payload: false,
            });
            navigate(`/project?tab=${tab}`, {
              ...(tab === "default-recommendations" && {
                state: { referrer: "feature-summary", executionMode },
              }),
            });
          }, 500);
        });
    }
  };

  return (
    <DefaultLayout>
      <div className="relative">
        <div className="w-full flex flex-col items-center justify-center py-7">
          <img src={SummaryImg} alt="summary img" className="w-10 h-10 mb-2" />
          <p className="text-black text-lg font-semibold text-center mb-1">
            Features Summary
          </p>
          <p className="text-sm text-black">
            Please verify the information below and select at least one feature
            to continue
          </p>
        </div>
        <div className="px-5 h-[calc(100vh-290px)] overflow-y-auto absolute left-0 right-0" id="features-container">
          <SquareCheckbox
            labelClassName="left-[25px] top-[-3px] font-semibold"
            label="Select All"
            checked={checkedAll}
            pending={pendingAll}
            onChange={(val: boolean) => handleChecked(-1, val)}
          />
          <div className="mt-4">
            {data?.map((item: any, idx: number) => (
              <AccordionItem
                key={idx}
                tabLabelChild={
                  <div>
                    <SquareCheckbox
                      labelClassName="left-[25px] top-[-6px] font-semibold text-xl"
                      label={item?.category}
                      checked={item?.checked}
                      pending={item?.pending}
                      onChange={(val: boolean) => handleChecked(idx, val)}
                    />
                  </div>
                }
                className="border-1 border-gray-c1c rounded-lg mb-4"
                labelChildClass="text-xl text-black ml-4"
                labelClass="py-2 border-b border-gray-c1c mx-[-2px]"
                dragIcon={false}
              >
                <FeatureSummaryItem
                  data={item?.data}
                  handleSubChecked={handleSubChecked}
                  index={idx}
                  unit={projectInfo?.unit}
                />
              </AccordionItem>
            ))}
          </div>
        </div>

        <div className="fixed bottom-[20px] left-0 right-0 h-[54px] px-4 py-2 flex justify-end items-center border-t border-gray-efe bg-white">
          <div className="inline-flex mr-auto gap-[8px] items-center">
            <SquareCheckbox
              className="w-[16px] min-h-[20px] min-w-[20px]"
              checked={defaultRecommendationCheck}
              onChange={() => {
                setDefaultRecommendationCheck((value) => !value);
              }}
            />
            <div className="inline-flex flex-col">
              <div className="text-[12px] text-gray-700 font-inter font-semibold">
                Select default recommendations
              </div>
              <div className="text-[10px] text-gray-700 font-inter">
                Skip manual selection and proceed with defaults.
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-4 font-semibold">
            <Button
              size="md"
              variant="gray"
              onClick={() => navigate("/project?tab=feature")}
            >
              Back
            </Button>
            <Button
              size="md"
              disabled={!visibleFeatures.length}
              onClick={() => {
                if (defaultRecommendationCheck) {
                  setIsOpen(true);
                } else {
                  handleNext("machine");
                }
              }}
            >
              {defaultRecommendationCheck ? "Next: Summary" : "Save & Continue"}
            </Button>
          </div>
        </div>
      </div>
      <ConfirmDefaultRecommendationsSelection
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleContinue={(mode: number) =>
          handleNext("default-recommendations", mode)
        }
      />
    </DefaultLayout>
  );
};

export default FeatureSummary;
