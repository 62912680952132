import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
} from "@mui/material";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  maxWidth: "450px",
  width: "100%",
  borderRadius: "16px",
  outline: "none",
};

interface IAutomaticFeatureRecognitionSelectionModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleContinue: Function;
}

const AutomaticFeatureRecognitionSelectionModal: React.FC<
  IAutomaticFeatureRecognitionSelectionModal
> = ({ isOpen, setIsOpen, handleContinue }) => {
  const [selectedOptions, setSelectedOptions] = useState<any>({
    hole: true,
    face: true,
    pocket: true,
    edge: true,
    groove: true,
  });
  const [selectedCount, setSelectedCount] = useState(5);

  useEffect(() => {
    if (isOpen) {
      setSelectedOptions({
        hole: true,
        face: true,
        pocket: true,
        edge: true,
        groove: true,
      });
      setSelectedCount(5);
    }
  }, [isOpen]);

  const handleChange = useCallback((event: any) => {
    const { name, checked } = event.target;
    if (name === "selectAll") {
      // Handling select all/de-select all case.
      setSelectedOptions({
        hole: checked,
        face: checked,
        pocket: checked,
        edge: checked,
        groove: checked,
      });
      setSelectedCount(checked ? 5 : 0);
    } else {
      setSelectedOptions((prev: any) => ({
        ...prev,
        [name]: checked,
      }));
      setSelectedCount((prev) => (prev + checked ? 1 : -1));
    }
  }, []);

  return (
    <div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={modalStyle}>
          <div className="flex flex-row items-center justify-between p-[16px] border-b border-gray-300">
            <h3 className="text-lg font-medium leading-6 text-black-222">
              Automatic Feature Recognition
            </h3>
            <FiX
              className="w-6 h-6 cursor-pointer text-black-222"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "60vh",
            }}
          >
            <div className="p-[16px] inline-flex flex-col gap-[4px] w-full">
              <FormControlLabel
                className="!m-0 !p-[8px] flex !gap-[12px] items-center "
                control={
                  <Checkbox
                    name="selectAll"
                    checked={selectedCount === 5}
                    onChange={handleChange}
                    className="!p-0"
                    indeterminate={selectedCount > 0 && selectedCount < 5}
                  />
                }
                label="Select all"
                slotProps={{
                  typography: {
                    className:
                      "text-[10px] font-semibold font-inter text-gray-700",
                  },
                }}
              />
              <FormGroup className="!rounded-[12px] border-[1px] border-gray-200 overflow-hidden">
                <FormControlLabel
                  className="!m-0 !p-[8px] flex !gap-[12px] items-center bg-gray-50 border-b border-gray-200"
                  control={
                    <Checkbox
                      name="hole"
                      className="!p-0"
                      checked={selectedOptions.hole}
                      onChange={handleChange}
                    />
                  }
                  label="Holes"
                  slotProps={{
                    typography: {
                      className:
                        "text-[14px] font-medium font-inter text-gray-700",
                    },
                  }}
                />
                <FormControlLabel
                  className="!m-0 !p-[8px] flex !gap-[12px] items-center bg-white border-b border-gray-200"
                  control={
                    <Checkbox
                      name="face"
                      className="!p-0"
                      checked={selectedOptions.face}
                      onChange={handleChange}
                    />
                  }
                  label="Faces"
                  slotProps={{
                    typography: {
                      className:
                        "text-[14px] font-medium font-inter text-gray-700",
                    },
                  }}
                />
                <FormControlLabel
                  className="!m-0 !p-[8px] flex !gap-[12px] items-center bg-gray-50 border-b border-gray-200"
                  control={
                    <Checkbox
                      name="pocket"
                      className="!p-0"
                      checked={selectedOptions.pocket}
                      onChange={handleChange}
                    />
                  }
                  label="Pockets"
                  slotProps={{
                    typography: {
                      className:
                        "text-[14px] font-medium font-inter text-gray-700",
                    },
                  }}
                />
                <FormControlLabel
                  className="!m-0 !p-[8px] flex !gap-[12px] items-center bg-white border-b border-gray-200"
                  control={
                    <Checkbox
                      name="edge"
                      className="!p-0"
                      checked={selectedOptions.edge}
                      onChange={handleChange}
                    />
                  }
                  label="Edges"
                  slotProps={{
                    typography: {
                      className:
                        "text-[14px] font-medium font-inter text-gray-700",
                    },
                  }}
                />
                <FormControlLabel
                  className="!m-0 !p-[8px] flex !gap-[12px] items-center bg-gray-50"
                  control={
                    <Checkbox
                      name="groove"
                      className="!p-0"
                      checked={selectedOptions.groove}
                      onChange={handleChange}
                    />
                  }
                  label="Grooves"
                  slotProps={{
                    typography: {
                      className:
                        "text-[14px] font-medium font-inter text-gray-700",
                    },
                  }}
                />
              </FormGroup>
            </div>
          </Box>
          <div className="flex justify-center w-full p-[16px] border-t border-gray-300">
            <Button
              className="px-[16px] py-[8px] text-[14px] font-semibold font-inter normal-case rounded-[4px] w-[150px] text-center  bg-surface-default text-white hover:opacity-80 active:opacity-50"
              onClick={() => {
                const featureTypeArray: string[] = [];
                Object.keys(selectedOptions).forEach((key: any) => {
                  if (selectedOptions[key]) {
                    featureTypeArray.push(key);
                  }
                });
                if (!featureTypeArray.length) {
                  toast.error("Please atleast select one option!");
                  return;
                }
                setIsOpen(false);
                handleContinue(featureTypeArray);
              }}
            >
              Continue
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AutomaticFeatureRecognitionSelectionModal;
