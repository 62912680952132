import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "../input.css";
import OtpInput from "react-otp-input";
import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "context/GlobalContext";
import LogoImg from "assets/images/logo.svg";
import Button from "components/Button";
import Input from "components/Input";
import cn from "classnames";
import zxcvbn from "zxcvbn";
import {
  SET_LOADING_BAR,
  emailValidation,
  passwordValidation,
  getConfirmPasswordValidation,
} from "constant";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";

const validationSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  confirmPassword: getConfirmPasswordValidation(),
});

const VerifyPassword = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { forgotForm } = state;
  const navigate = useNavigate();
  const [score, setScore] = useState(0);
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch({
          type: SET_LOADING_BAR,
          payload: true,
        });
        const response = await Auth.forgotPasswordSubmit(
          values?.email?.toLowerCase(),
          values.code,
          values.password
        );
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
        if (response?.toLowerCase() !== "success") return;
        navigate("/login");
      } catch (err: any) {
        err?.message && toast.error(err?.message);
        dispatch({
          type: SET_LOADING_BAR,
          payload: false,
        });
      }
      // navigate("/login");
    },
  });

  useEffect(() => {
    if (!forgotForm || !forgotForm?.email) return;
    setFieldValue("email", forgotForm?.email);
  }, [forgotForm]);

  useEffect(() => {
    setScore(zxcvbn(values.password).score);
  }, [values.password]);

  return (
    <div className="flex items-center justify-center w-full h-full relative">
      <div className="fixed top-0 right-0 left-0 bottom-0 bg-auth-background w-full h-full bg-cover "></div>
      <div className="fixed top-0 right-0 left-0 bottom-0 splash-background"></div>
      <div className="w-6/12 h-full flex flex-col justify-between px-5 py-10 z-[1]">
        <img src={LogoImg} alt="logo" className="max-w-[180px] h-fit" />
        <div className="flex flex-col">
          <p className="text-surface-default font-semibold text-lg">
            Welcome to
          </p>
          <p className="text-black font-bold text-[40px] mb-6">
            Generative Programming
          </p>
          <p className="text-gray-494 font-medium">
            A tool that brings the power of artificial intelligence to CNC
            programmers by recommending machining strategies, optimal cutting
            tools & machining parameters, and more based on the specific
            features in your CAD files.
          </p>
        </div>
        <div className="flex flex-row justify-between items-center">
          <span className="text-gray-424 text-xs font-semibold">
            2025 © Lambda Function, Inc.
          </span>
          <span className="text-gray-424 text-xs font-semibold">v1.0.0</span>
        </div>
      </div>
      <div className="w-6/12 h-full flex justify-center relative pt-10 pb-16">
        <div className="w-full h-full overflow-auto px-5 flex justify-center items-center">
          <div className="flex flex-col max-w-[50vw] p-px my-auto h-fit w-8/12 min-w-[350px]">
            <div className="mb-4">
              <p className="font-semibold text-[27px] text-black mb-1">
                Verify email
              </p>
              <div className="rounded bg-surface-default w-[48px] h-1"></div>
            </div>
            <p className="font-semibold text-gray-696 text-sm mb-4 max-w-[420px]">
              A verification code has been sent to you. Enter code below
            </p>
            <form onSubmit={handleSubmit}>
              {!forgotForm?.email && (
                <Input
                  dataType="string"
                  handleFocus={handleBlur}
                  label="Email"
                  type="email"
                  name="email"
                  className="min-w-[350px] mb-4"
                  subClassName="!shadow-form-input !bg-white !rounded-xl"
                  inputClassName="bg-transparent"
                  value={values?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                />
              )}
              <div className="flex flex-row space-x-[10px] mb-4 otp">
                <OtpInput
                  value={values?.code}
                  onChange={(otp) => setFieldValue("code", otp)}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <div className="mb-2">
                <Input
                  dataType="string"
                  handleFocus={handleBlur}
                  label="Enter new password"
                  type="password"
                  name="password"
                  className="min-w-[350px] mb-2"
                  placeholderClassName="text-black-222"
                  subClassName="!shadow-form-input !bg-white !rounded-xl"
                  inputClassName="bg-transparent"
                  value={values?.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.password && errors.password}
                  helperText={touched.password && errors.password}
                />
                <div className="flex flex-row items-center space-x-2">
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-red-fb2": score === 1,
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-red-fb2": score === 1,
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-yellow-ffa": score === 2,
                      "!bg-green-1db": score === 3 || score === 4,
                    })}
                  ></div>
                  <div
                    className={cn("rounded w-full bg-gray-d9d h-1", {
                      "!bg-green-1db": score === 4,
                    })}
                  ></div>
                </div>
              </div>
              <Input
                dataType="string"
                handleFocus={handleBlur}
                label="Repeat new password"
                type="password"
                name="confirmPassword"
                className="min-w-[350px] mb-4"
                placeholderClassName="text-black-222"
                subClassName="!shadow-form-input !bg-white !rounded-xl"
                inputClassName="bg-transparent"
                value={values?.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.confirmPassword && errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <Button
                type="submit"
                className="w-full rounded-xl text-lg py-4 font-semibold mb-[10px]"
                disabled={values.code?.length < 6 || !isValid}
              >
                Verify Email
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPassword;
