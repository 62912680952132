import { Dispatch, SetStateAction } from "react";

export const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "420px",
  boxShadow: "none",
  outline: "none",
  width: "100%",
};
export interface IUnvisitedFeaturesModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  screen: "strategy" | "tools&param";
  featureList: String[];
  handleContinue: Function;
}
