import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface IDisplayCamSpecificFeatureName {
  namesArray: string[];
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#D1E9FF",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#D1E9FF",
    color: "black",
    maxWidth: "200px",
    padding: "8px",
    fontSize: theme.typography.pxToRem(12),
  },
}));

const DisplayCamSpecificFeatureName: React.FC<
  IDisplayCamSpecificFeatureName
> = ({ namesArray }) => {
  let fullName = namesArray?.join(", ") || "";
  if (!fullName) {
    return <></>;
  }

  return (
    <HtmlTooltip
      title={<div className="text-wrap whitespace-normal">{fullName}</div>}
      arrow
    >
      <span className="truncate">&nbsp;({fullName})</span>
    </HtmlTooltip>
  );
};

export default DisplayCamSpecificFeatureName;
