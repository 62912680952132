import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import clsx from "clsx";
import { ListSubheader } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useCallback, useEffect, useRef, useState } from "react";

export interface IOption {
  label: string | number;
  value: string;
  type: number;
  style?: string;
}

interface ISelect {
  value?: string;
  onChange?: any;
  onBlur?: any;
  options: IOption[];
  className?: string;
  handleClose?: Function;
  placeholder?: string;
  invalid?: any;
  helperText?: any;
  disabled?: boolean;
  size?: any;
  isTag?: boolean;
  name?: string;
}

/**
 * Version 2 of Select component using Material UI library
 *
 * Mapping for options types:
 *
 *    0 -> Normal menu item
 *    1 -> Sub group heading
 */
const SelectV2: React.FC<ISelect> = ({
  value,
  options,
  size = "small",
  onChange = () => {},
  placeholder = "Select",
  handleClose,
  helperText,
  disabled = false,
  ...args
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const valueRef = useRef("");

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      valueRef.current = event.target.value;
      onChange(event.target.value);
    },
    [onChange]
  );

  useEffect(() => {
    valueRef.current = value ?? "";
  }, [value]);

  return (
    <div className="w-full select-v2">
      <div
        className={clsx("w-full !rounded-[8px]", {
          "min-h-[36px]": size === "small",
          "shadow-input-2": !isOpen,
          "border-surface-default border border-solid": isOpen,
        })}
      >
        <FormControl fullWidth>
          <Select
            value={value}
            onChange={handleChange}
            disabled={disabled}
            sx={{
              ...(size === "small" && {
                minHeight: "36px",
                ".MuiSelect-select": {
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#475467",
                  lineHeight: "16px",
                  padding: "0 24px 0 12px !important",
                },
              }),
              ...(disabled && { backgroundColor: "#EEEEEE" }),
            }}
            className="font-inter"
            IconComponent={KeyboardArrowDownIcon}
            onOpen={() => setIsOpen(true)}
            onClose={() => {
              handleClose?.(valueRef.current);
              setIsOpen(false);
            }}
            displayEmpty
            {...args}
          >
            <MenuItem disabled value="" className="hidden">
              <span className="text-[14px]  font-medium text-gray-500 h-auto leading-[16px] font-inter">
                {placeholder}
              </span>
            </MenuItem>
            {options?.map((item: any, index: number) => {
              if (item.type === 1) {
                return (
                  <ListSubheader
                    key={index}
                    sx={{ fontSize: "12px" }}
                    className="text-[12px] px-[12px] pt-[4px] font-medium text-gray-500 h-auto leading-[16px] font-inter flex gap-[6px] items-center whitespace-nowrap"
                  >
                    {item.label}{" "}
                    <span className="w-full bg-gray-200 h-[1px]"></span>
                  </ListSubheader>
                );
              } else {
                return (
                  <MenuItem
                    key={index}
                    className={clsx(
                      "px-[12px] py-[4px] text-[14px] font-medium font-inter",
                      item.style
                    )}
                    value={item.value}
                  >
                    <div className="break-words whitespace-normal overflow-visible">
                      {item.label}
                    </div>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </div>
      {helperText && (
        <p
          className={clsx("text-red-ff0 pt-1 text-sm", {
            "!text-[12px]": size === "small",
          })}
        >
          {helperText}
        </p>
      )}
    </div>
  );
};

export default SelectV2;
