import { useContext, useEffect, useMemo } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  SET_MACHINE_SETUP,
  SLOT_OPEN_SIDES_OPTIONS,
  SLOT_SLOT_TYPE_OPTIONS,
  SLOT_TYPE_OPTIONS,
  SIDE_FACE_BOTTOM_TYPE_OPTIONS,
  decimalRegExp,
  SLOT_VALIDATION,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  SLOT_LOCATION_OPTIONS,
  onlyNumberRegexExp,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import TooltipContent from "../../FeatureToolTipContent";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipV2 from "components/ToolTipV2";
import { roundOff } from "utils";

interface ISlotForm {
  feature_info?: any;
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const init_slot_edge = {
  length: "",
  width: "",
  depth: "",
  slot_type: "",
  type: "",
  open_sides: "",
  corner_radius: "",
  section_depth: "",
  section_width: "",
  bottom_type: "",
  bottom_radius: "",
  location: "",
  quantity: "",
  horizontal_clearance: "",
  vertical_clearance: "",
};

const SlotForm: React.FC<ISlotForm> = ({
  feature_info,
  feature_id,
  setAllFieldsTouched,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.length.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.length.min[projectInfo?.unit || "mm"]
            }`
          ),
        width: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.width.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.width.min[projectInfo?.unit || "mm"]
            }`
          ),
        depth: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.depth.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.depth.min[projectInfo?.unit || "mm"]
            }`
          ),
        quantity: QUANTITY_VALIDATION,
        corner_radius: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.corner_radius.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.corner_radius.min[projectInfo?.unit || "mm"]
            }`
          )
          .when("width", (width, schema) => {
            return width
              ? schema.max(
                  roundOff(width / 2),
                  `Value must be ${roundOff(width / 2)} or less`
                )
              : schema;
          }),
        bottom_radius: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.bottom_radius.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.bottom_radius.min[projectInfo?.unit || "mm"]
            }`
          )
          .when("depth", (depth, schema) => {
            return depth
              ? schema.max(depth, `Value must be ${depth} or less`)
              : schema;
          }),
        section_depth: Yup.number()
          .required("This field is required")
          .min(
            SLOT_VALIDATION.section_depth.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SLOT_VALIDATION.section_depth.min[projectInfo?.unit || "mm"]
            }`
          ),
        section_width: Yup.number()
          .required("This field is required")
          .when("width", (width, schema) => {
            return width
              ? schema.max(width + 0.1, `Value must be atleast ${width + 0.1}`)
              : schema;
          }),
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_slot_edge).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_slot_edge,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!contourList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_slot_contour).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: contourList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [contourList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "slot_type":
        if (value === "simple") {
          delete updated_feature.section_depth;
          delete updated_feature.section_width;
          setFieldValue("section_depth", "");
          setFieldValue("section_width", "");
        } else if (value === "t-slot" || value === "dovetail") {
          delete updated_feature.corner_radius;
          delete updated_feature.location;
          setFieldValue("corner_radius", "");
          setFieldValue("location", "");

          setFieldValue("type", "open");
          setFieldValue("open_sides", "both");

          updated_feature = {
            ...updated_feature,
            type: "open",
            open_sides: "both",
          };
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "type":
        if (value === "closed") {
          setFieldValue("open_sides", "");
          setFieldValue("location", "");
          delete updated_feature.open_sides;
          delete updated_feature.location;
        } else if (value === "open") {
          delete updated_feature.corner_radius;
          setFieldValue("corner_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "open_sides":
        if (value === "both") {
          delete updated_feature.corner_radius;
          setFieldValue("corner_radius", "");
        } else if (value === "one") {
          delete updated_feature.location;
          setFieldValue("location", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "bottom_type":
        if (value === "through") {
          delete updated_feature.bottom_radius;
          setFieldValue("bottom_radius", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "location":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });
    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_CONTOUR_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_slot_edge).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("slot", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Width"
              className=""
              type="text"
              name="width"
              unit={projectInfo?.unit || "mm"}
              value={values?.width}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("width", values?.width);
              }}
              infoTooltipTitle={TooltipContent("slot", "width")}
              invalid={touched.width && errors.width}
              helperText={touched.width && errors.width}
              regex={decimalRegExp}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Depth"
              className="mr-2"
              type="text"
              name="depth"
              unit={projectInfo?.unit || "mm"}
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              infoTooltipTitle={TooltipContent("slot", "depth")}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Slot Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("slot", "slotType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={SLOT_SLOT_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("slot_type", val, true)}
              value={values?.slot_type}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("slot", "type")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={SLOT_TYPE_OPTIONS}
              setValue={(val: any) => handleChangeFunc("type", val, true)}
              value={values?.type}
              disabled={
                values?.slot_type === "t-slot" ||
                values?.slot_type === "dovetail"
              }
            />
          </div>
        </div>
        {(values?.slot_type === "t-slot" ||
          values?.slot_type === "dovetail") && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Section depth"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="section_depth"
                value={values?.section_depth}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("section_depth", values?.section_depth);
                }}
                infoTooltipTitle={TooltipContent("slot", "sectionDepth")}
                invalid={touched.section_depth && errors.section_depth}
                helperText={touched.section_depth && errors.section_depth}
                regex={decimalRegExp}
              />
            </div>
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Section width"
                className=""
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="section_width"
                value={values?.section_width}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("section_width", values?.section_width);
                }}
                infoTooltipTitle={TooltipContent("slot", "sectionWidth")}
                invalid={touched.section_width && errors.section_width}
                helperText={touched.section_width && errors.section_width}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
        {values?.type === "open" && (
          <div className="flex flex-row mt-4">
            <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
              <div className="flex align-baseline mr-auto">
                <label className="font-semibold text-gray-475 truncate mr-2">
                  Open Sides
                </label>
                <TooltipV2
                  openOnHover={false}
                  title={TooltipContent("slot", "openSides")}
                >
                  <div className="my-auto">
                    <img
                      className="inline-block"
                      src={FeatureInfoIcon}
                      alt=""
                      height={16}
                      width={16}
                    />
                  </div>
                </TooltipV2>
              </div>
              <RadioComponent
                options={SLOT_OPEN_SIDES_OPTIONS}
                setValue={(val: any) =>
                  handleChangeFunc("open_sides", val, true)
                }
                value={values?.open_sides}
                disabled={
                  values?.slot_type === "t-slot" ||
                  values?.slot_type === "dovetail"
                }
              />
            </div>
            {values?.open_sides === "one" && (
              <div className="w-6/12">
                <Input
                  handleFocus={handleBlur}
                  placeholder="Corner Radius"
                  className=""
                  type="text"
                  unit={projectInfo?.unit || "mm"}
                  name="corner_radius"
                  value={values?.corner_radius}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    handleChangeFunc("corner_radius", values?.corner_radius);
                  }}
                  infoTooltipTitle={TooltipContent("slot", "cornerRadius")}
                  invalid={touched.corner_radius && errors.corner_radius}
                  helperText={touched.corner_radius && errors.corner_radius}
                  regex={decimalRegExp}
                />
              </div>
            )}
            {values?.open_sides === "both" &&
              values?.slot_type === "simple" && (
                <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
                  <div className="flex align-baseline mr-auto">
                    <label className="font-semibold text-gray-475 truncate mr-2">
                      Location
                    </label>
                    <TooltipV2
                      openOnHover={false}
                      title={TooltipContent("slot", "location")}
                    >
                      <div className="my-auto">
                        <img
                          className="inline-block"
                          src={FeatureInfoIcon}
                          alt=""
                          height={16}
                          width={16}
                        />
                      </div>
                    </TooltipV2>
                  </div>
                  <RadioComponent
                    options={SLOT_LOCATION_OPTIONS}
                    setValue={(val: any) =>
                      handleChangeFunc("location", val, true)
                    }
                    value={values?.location}
                  />
                </div>
              )}
          </div>
        )}
        {values?.type === "closed" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Corner Radius"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="corner_radius"
                value={values?.corner_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("corner_radius", values?.corner_radius);
                }}
                infoTooltipTitle={TooltipContent("slot", "cornerRadius")}
                invalid={touched.corner_radius && errors.corner_radius}
                helperText={touched.corner_radius && errors.corner_radius}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row mt-4">
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-2">
                Bottom Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("slot", "bottomType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={SIDE_FACE_BOTTOM_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("bottom_type", val, true)
              }
              value={values?.bottom_type}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className=""
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("slot", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
        </div>
        {values?.bottom_type === "solid" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Bottom radius"
                className="mr-2"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="bottom_radius"
                value={values?.bottom_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("bottom_radius", values?.bottom_radius);
                }}
                infoTooltipTitle={TooltipContent("slot", "bottomRadius")}
                invalid={touched.bottom_radius && errors.bottom_radius}
                helperText={touched.bottom_radius && errors.bottom_radius}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("slot", "verticalClearance")}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full "
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent("slot", "horizontalClearance")}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotForm;
