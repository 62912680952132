import Button from "components/Button";
import Modal from "components/Modal";
import ClossInValidPopUp from "assets/images/icons/charm_cross.svg";
import { useNavigate } from "react-router-dom";

interface IFeatureInputErrorCountModal {
  isOpen: boolean;
  setIsOpen: any;
  errorCounts: any;
  setSelectedItem: any;
  setErrorFeature: any;
}

const FeatureInputErrorCountModal: React.FC<IFeatureInputErrorCountModal> = ({
  isOpen,
  setIsOpen,
  errorCounts = [],
  setSelectedItem,
  setErrorFeature,
}) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="custom">
      <Modal.Header className="h-auto border-b border-gray-200 p-[16px]">
        <div className="flex justify-between gap-2 text-gray-700 font-semibold">
          <div>
            <h3 className="text-[20px]">Missing Information Alert</h3>
            <div className="text-[10px]">
              Are you sure you want to proceed? Missing inputs may cause no
              machining strategy recommendations.
            </div>
          </div>
          <button onClick={() => setIsOpen(false)} className="outline-none">
            <img src={ClossInValidPopUp} alt="" width="20" />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-[16px]">
        <div className="rounded-[8px] border border-gray-200 m-0 h-auto">
          <table className="m-0 w-full">
            <thead>
              <tr>
                <th className="text-[14px] font-medium text-gray-600 px-[8px] py-[4px]">
                  Feature Name
                </th>
                <th className="text-[14px] font-medium text-gray-600 px-[8px] py-[4px] text-center">
                  Missing inputs count
                </th>
                <th className="text-[14px] font-medium text-gray-600 px-[8px] py-[4px] text-center">
                  View
                </th>
              </tr>
            </thead>
            <tbody>
              {errorCounts?.map((error: any, index: any) => (
                <tr
                  key={index}
                  className={`border-t border-gray-200 ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="text-[16px] font-medium text-gray-700 px-[8px] py-[4px]">
                    {error.title}
                  </td>
                  <td className="text-[16px] font-medium text-gray-700 px-[8px] py-[4px] text-center">
                    {error.count}
                  </td>
                  <td className="text-[16px] font-medium text-gray-700 px-[8px] py-[4px] text-center">
                    <button
                      onClick={() => {
                        setSelectedItem(error.type);
                        setErrorFeature({
                          featureId: error.featureId,
                          tagId: error.tagId,
                          showErrors: true,
                        });
                        setIsOpen(false);
                      }}
                    >
                      <svg
                        className="cursor-pointer"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.16927 4.99967C6.72724 4.99967 6.30332 5.17527 5.99076 5.48783C5.6782 5.80039 5.5026 6.22431 5.5026 6.66634V13.333C5.5026 13.775 5.6782 14.199 5.99076 14.5115C6.30332 14.8241 6.72724 14.9997 7.16927 14.9997H13.8359C14.278 14.9997 14.7019 14.8241 15.0144 14.5115C15.327 14.199 15.5026 13.775 15.5026 13.333V11.6663C15.5026 11.4453 15.5904 11.2334 15.7467 11.0771C15.903 10.9208 16.1149 10.833 16.3359 10.833C16.557 10.833 16.7689 10.9208 16.9252 11.0771C17.0815 11.2334 17.1693 11.4453 17.1693 11.6663V13.333C17.1693 14.2171 16.8181 15.0649 16.193 15.69C15.5678 16.3152 14.72 16.6663 13.8359 16.6663H7.16927C6.28522 16.6663 5.43737 16.3152 4.81225 15.69C4.18713 15.0649 3.83594 14.2171 3.83594 13.333V6.66634C3.83594 5.78229 4.18713 4.93444 4.81225 4.30932C5.43737 3.6842 6.28522 3.33301 7.16927 3.33301H8.83594C9.05695 3.33301 9.26891 3.42081 9.42519 3.57709C9.58147 3.73337 9.66927 3.94533 9.66927 4.16634C9.66927 4.38735 9.58147 4.59932 9.42519 4.7556C9.26891 4.91188 9.05695 4.99967 8.83594 4.99967H7.16927ZM12.1693 4.99967C11.9483 4.99967 11.7363 4.91188 11.58 4.7556C11.4237 4.59932 11.3359 4.38735 11.3359 4.16634C11.3359 3.94533 11.4237 3.73337 11.58 3.57709C11.7363 3.42081 11.9483 3.33301 12.1693 3.33301H16.3359C16.557 3.33301 16.7689 3.42081 16.9252 3.57709C17.0815 3.73337 17.1693 3.94533 17.1693 4.16634V8.33301C17.1693 8.55402 17.0815 8.76598 16.9252 8.92226C16.7689 9.07854 16.557 9.16634 16.3359 9.16634C16.1149 9.16634 15.903 9.07854 15.7467 8.92226C15.5904 8.76598 15.5026 8.55402 15.5026 8.33301V6.17801L12.7593 8.92301C12.6818 9.00049 12.5898 9.06195 12.4886 9.10388C12.3873 9.14581 12.2788 9.16739 12.1693 9.16739C12.0597 9.16739 11.9512 9.14581 11.85 9.10388C11.7487 9.06195 11.6568 9.00049 11.5793 8.92301C11.5018 8.84553 11.4403 8.75355 11.3984 8.65231C11.3565 8.55108 11.3349 8.44258 11.3349 8.33301C11.3349 8.22343 11.3565 8.11493 11.3984 8.0137C11.4403 7.91247 11.5018 7.82049 11.5793 7.74301L14.3243 4.99967H12.1693Z"
                          fill="#344054"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer className="h-auto border-t border-gray-200 p-[16px] !m-0">
        <div className="flex flex-row justify-center w-full">
          <Button
            size="md"
            className="modal-button"
            onClick={() => {
              setIsOpen(false);
              navigate(`/project?tab=feature-summary`, {
                state: {
                  referrer: "feature",
                },
              });
            }}
          >
            Yes, Continue
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FeatureInputErrorCountModal;
