import * as Yup from "yup";

export interface IProjectItem {
  label?: string;
  value?: string | number;
  className?: string;
}

export const INIT_PROJECT = {
  projectName: "",
  machineName: "",
  cam_software: "",
  material: "",
  subMaterial: "",
  orientation: "",
  numberOfAxis: "",
  unit: "",
  toolLibraryId: [],
  cadFilePath: "",
  userSelectionId: "",
  machineType: "",
  max_spindle_speed: {},
  workTableType: "",
  workTableBedSize: {},
  rawPartDimensions: {},
  machinedPartDimensions: {},
  numberOfSetups: "1",
  cadPartId: "",
};

export const rawPartDimensionsValidation = Yup.object()
  .shape({
    length: Yup.number().positive().required("This field is required"),
    width: Yup.number().positive().required("This field is required"),
    height: Yup.number().positive().required("This field is required"),
  })
  .required();

export const machinedPartDimensionsValidation = Yup.object()
  .shape({
    length: Yup.number().positive().required("This field is required"),
    width: Yup.number().positive().required("This field is required"),
    height: Yup.number().positive().required("This field is required"),
  })
  .required();

export const validationSchema = Yup.object().shape({
  projectName: Yup.string().required("This field is required"),
  cadPartId: Yup.string().required("This field is required"),
  cam_software: Yup.string().required("This field is required"),
  machineName: Yup.string().required("This field is required"),
  material: Yup.string().required("This field is required"),
  orientation: Yup.string().required("This field is required"),
  numberOfAxis: Yup.number().required("This field is required"),
  unit: Yup.string().required("This field is required"),
  numberOfSetups: Yup.number()
    .min(1, "Min value limit is 1")
    .max(10, "Max value limit is 10")
    .required("This field is required"),
  // rpm: Yup.string()
  //   .required("This field is required")
  //   .matches(/^[1-9]\d*$/, "RPM must be a valid Positive Integer")
  //   .test("Max-value-test", "Max. value limit 40,000", function (value) {
  //     return Number(value) <= 40000;
  //   }),
  subMaterial: Yup.string().required("This field is required"),
  machineType: Yup.string().required("This field is required"),
  max_spindle_speed: Yup.object().test(
    "validate-max_spindle_speed",
    "This field is required",
    function (value) {
      return value && !isNaN(value.max_spindle_speed);
    }
  ),
  workTableType: Yup.string().required("This field is required"),
  workTableBedSize: Yup.object().test(
    "validate-dimensions",
    "This field is required",
    function (value) {
      return (
        value &&
        !isNaN(value.length) &&
        !isNaN(value.width) &&
        !isNaN(value.height)
      );
    }
  ),
  rawPartDimensions: Yup.mixed().when("cam_software", {
    is: (cam_software: string) => cam_software === "siemens",
    then: rawPartDimensionsValidation.test(
      "raw-part-dimensions-greater-than-equal-to-machined-part-dimensions",
      "Raw dimensions cannot be smaller than the bounding box.",
      function (this: any, rawDimensions: any) {
        const { machinedPartDimensions } = this.parent;
        if (!rawDimensions || !machinedPartDimensions) return true;
        return (
          rawDimensions.length >= machinedPartDimensions.length &&
          rawDimensions.width >= machinedPartDimensions.width &&
          rawDimensions.height >= machinedPartDimensions.height
        );
      }
    ),
    otherwise: Yup.mixed().notRequired(),
  }),
  machinedPartDimensions: Yup.mixed().when("cam_software", {
    is: (cam_software: string) => cam_software === "siemens",
    then: machinedPartDimensionsValidation,
    otherwise: Yup.mixed().notRequired(),
  }),
});

export const extractDataValidationSchema = Yup.object().shape({
  rawPartDimensions: rawPartDimensionsValidation,
  machinedPartDimensions: machinedPartDimensionsValidation,
  projectName: Yup.string().required(),
  cadPartId: Yup.string().required(),
  unit: Yup.string().oneOf(["mm", "in"]).required(),
  message: Yup.string(),
});

export const initialMachineDetailsState = {
  machineList: [],
  spindleOptions: [],
  workTableOptions: [],
  workTableBedSizeOptions: {},
};

export function machineDetailsReducer(
  state: typeof initialMachineDetailsState,
  action: any
) {
  switch (action.type) {
    case "SET_MACHINE_LIST":
      return { ...initialMachineDetailsState, machineList: action.payload };
    case "SET_SPINDLE_OPTIONS":
      return {
        ...state,
        ...action.payload,
        // spindleOptions: action.payload,
        // workTableOptions: action.payload.,
        // workTableBedSizeOptions: {},
      };
    case "SET_WORK_TABLE_OPTIONS":
      return {
        ...state,
        workTableOptions: action.payload.workTableOptions,
        workTableBedSizeOptions: action.payload.workTableBedSizeOptions,
      };
    case "SET_WORK_TABLE_BED_SIZE_OPTIONS":
      return { ...state, workTableBedSizeOptions: action.payload };
    case "SET_DEFAULT_VALUES":
      return {
        ...initialMachineDetailsState,
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
