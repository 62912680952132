import { useContext, useEffect, useMemo } from "react";
import Input from "components/Input";
import RadioComponent from "components/RadioComponent";
import {
  SIDE_FACE_BOTTOM_TYPE_OPTIONS,
  SET_FACE_DATA,
  SET_MACHINE_SETUP,
  SIDE_FACE_SURFACE_TYPE_OPTIONS,
  SIDE_FACE_VALIDATION,
  POSITIVE_VALIDATION,
  QUANTITY_VALIDATION,
  decimalRegExp,
  onlyNumberRegexExp,
  TAPER_ANGLE_VALIDATION,
} from "constant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "context/GlobalContext";
import FeatureInfoIcon from "assets/images/icons/feature-info.svg";
import TooltipContent from "../../FeatureToolTipContent";
import TooltipV2 from "components/ToolTipV2";
import { roundOff } from "utils";

interface ISideFace {
  feature_info?: any;
  feature_id?: string;
  setAllFieldsTouched?: boolean;
}

const init_side_face = {
  length: "",
  depth: "",
  quantity: "",
  surface_type: "",
  stock_to_remove: "",
  taper_angle: "",
  taper_length: "",
  bottom_type: "",
  bottom_radius: "",
  bottom_width: "",
  vertical_clearance: "",
  horizontal_clearance: "",
};

const SideFace: React.FC<ISideFace> = ({
  feature_info,
  feature_id,
  setAllFieldsTouched,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { machiningSetups, projectInfo, setupNumber } = state;
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        length: Yup.number()
          .required("This field is required")
          .min(
            SIDE_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SIDE_FACE_VALIDATION.length.min[projectInfo?.unit || "mm"]
            }`
          ),
        depth: Yup.number()
          .required("This field is required")
          .min(
            SIDE_FACE_VALIDATION.depth.min[projectInfo?.unit || "mm"],
            `Value must be at least ${
              SIDE_FACE_VALIDATION.depth.min[projectInfo?.unit || "mm"]
            }`
          ),
        stock_to_remove: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        bottom_radius: POSITIVE_VALIDATION,
        bottom_width: Yup.number()
          .required("This field is required")
          .moreThan(0, "Value must be greater than 0"),
        quantity: QUANTITY_VALIDATION,
        taper_angle: TAPER_ANGLE_VALIDATION,
        taper_length: Yup.number().required("This field is required"),
        // .min(
        //   SIDE_FACE_VALIDATION.taper_length.min[projectInfo?.unit || "mm"],
        //   `Value must be at least ${
        //     SIDE_FACE_VALIDATION.taper_length.min[projectInfo?.unit || "mm"]
        //   }`
        // )
        // .when("depth", (depth, schema) => {
        //   return depth
        //     ? schema.max(depth - 0.0001, `Value must be less than ${depth}`)
        //     : schema;
        // }),
        vertical_clearance: POSITIVE_VALIDATION,
        horizontal_clearance: POSITIVE_VALIDATION,
      }),
    []
  );

  useEffect(() => {
    if (!feature_info) return;
    let formValues: any = {};
    Object.keys(init_side_face).forEach((key: string) => {
      formValues = {
        ...formValues,
        [key]: feature_info?.[key] ?? "",
      };
    });
    setValues(formValues);
  }, [feature_info]);

  const {
    handleChange,
    handleBlur,
    setValues,
    setTouched,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: init_side_face,
    validationSchema,
    onSubmit: () => {},
  });

  // useEffect(() => {
  //   if (!faceList?.[index]) return;
  //   let formValues: any = {};
  //   Object.keys(init_side_face).forEach((key: string) => {
  //     formValues = {
  //       ...formValues,
  //       [key]: faceList[index][key],
  //     };
  //   });
  //   setValues(formValues);
  // }, [faceList, index]);

  const handleChangeFunc = (key: string, value: any, flag: boolean = false) => {
    if (flag) setFieldValue(key, value);

    let updated_setups = [...machiningSetups];

    const setupIdx = updated_setups?.findIndex(
      (setup: any) => setup?.machiningSetupNumber === setupNumber
    );

    const feature_idx = updated_setups?.[setupIdx]?.features?.findIndex(
      (feature: any) => feature?.feature_id === feature_id
    );

    let updated_feature = {
      ...updated_setups?.[setupIdx]?.features?.[feature_idx]?.featureInformation
        ?.feature_data?.feature_info,
    };

    switch (key) {
      case "surface_type":
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        if (value === "flat" || value === "curved") {
          delete updated_feature.taper_angle;
          delete updated_feature.taper_length;
          setFieldValue("taper_angle", "");
          setFieldValue("taper_length", "");
        }
        break;
      case "bottom_type":
        if (value === "through") {
          delete updated_feature.bottom_radius;
          delete updated_feature.bottom_width;
          setFieldValue("bottom_radius", "");
          setFieldValue("bottom_width", "");
        }
        updated_feature = {
          ...updated_feature,
          [key]: value,
        };
        break;
      case "depth":
      case "taper_angle":
        if (value !== "") {
          if (values?.depth && values?.taper_angle) {
            const depth = Number(values.depth);
            const taperAngleInRadians =
              (Number(values.taper_angle) * Math.PI) / 180;

            if (taperAngleInRadians) {
              const taperLength = roundOff(
                depth / Math.sin(taperAngleInRadians)
              );
              setFieldValue("taper_length", taperLength);
              updated_feature = {
                ...updated_feature,
                taper_length: taperLength,
              };
            }
          }
          updated_feature = { ...updated_feature, [key]: Number(value) };
        } else {
          delete updated_feature["taper_length"];
          delete updated_feature[key];
          setFieldValue("taper_length", "");
        }
        break;
      default:
        if (value !== "") {
          updated_feature = {
            ...updated_feature,
            [key]: Number(value),
          };
        } else delete updated_feature[key];
    }

    updated_setups[setupIdx].features[
      feature_idx
    ].featureInformation.feature_data.feature_info = updated_feature;

    dispatch({
      type: SET_MACHINE_SETUP,
      payload: updated_setups,
    });

    // setFieldValue(key, value);
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key,
    //     value,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "parameter",
    //     value: null,
    //   },
    // });
    // dispatch({
    //   type: SET_FACE_DATA,
    //   payload: {
    //     index,
    //     key: "selected_strategy",
    //     value: null,
    //   },
    // });
  };

  useEffect(() => {
    if (setAllFieldsTouched) {
      setTouched(
        Object.keys(init_side_face).reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        ),
        false
      );
    }
  }, [setAllFieldsTouched, setTouched]);

  return (
    <div className="relative">
      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Feature Dimensions
        </p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Length"
              className="mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="length"
              value={values?.length}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("length", values?.length);
              }}
              infoTooltipTitle={TooltipContent("side_face", "length")}
              invalid={touched.length && errors.length}
              helperText={touched.length && errors.length}
              regex={decimalRegExp}
            />
          </div>
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Face Depth"
              className=""
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="depth"
              value={values?.depth}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("depth", values?.depth);
              }}
              infoTooltipTitle={TooltipContent("side_face", "depth")}
              invalid={touched.depth && errors.depth}
              helperText={touched.depth && errors.depth}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Stock Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Stock to remove"
              className="pr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="stock_to_remove"
              value={values?.stock_to_remove}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("stock_to_remove", values?.stock_to_remove);
              }}
              infoTooltipTitle={TooltipContent("side_face", "stockToRemove")}
              invalid={touched.stock_to_remove && errors.stock_to_remove}
              helperText={touched.stock_to_remove && errors.stock_to_remove}
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Bottom Inputs</p>
        <div className="flex justify-between items-center w-6/12 pt-[14px] flex-wrap">
          <div className="flex align-baseline mr-auto">
            <label className="font-semibold text-gray-475 truncate mr-2">
              Bottom Type
            </label>
            <TooltipV2
              openOnHover={false}
              title={TooltipContent("side_face", "bottomType")}
            >
              <div className="my-auto">
                <img
                  className="inline-block"
                  src={FeatureInfoIcon}
                  alt=""
                  height={16}
                  width={16}
                />
              </div>
            </TooltipV2>
          </div>
          <RadioComponent
            options={SIDE_FACE_BOTTOM_TYPE_OPTIONS}
            setValue={(val: any) => handleChangeFunc("bottom_type", val, true)}
            value={values?.bottom_type}
          />
        </div>
        {values?.bottom_type === "solid" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Bottom Radius"
                className="mr-2"
                type="text"
                name="bottom_radius"
                unit={projectInfo?.unit || "mm"}
                value={values?.bottom_radius}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("bottom_radius", values?.bottom_radius);
                }}
                infoTooltipTitle={TooltipContent("side_face", "bottomRadius")}
                invalid={touched.bottom_radius && errors.bottom_radius}
                helperText={touched.bottom_radius && errors.bottom_radius}
                regex={decimalRegExp}
              />
            </div>
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Bottom Width"
                className=""
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="bottom_width"
                value={values?.bottom_width}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("bottom_width", values?.bottom_width);
                }}
                infoTooltipTitle={TooltipContent("side_face", "bottomWidth")}
                invalid={touched.bottom_width && errors.bottom_width}
                helperText={touched.bottom_width && errors.bottom_width}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">Other Inputs</p>
        <div className="flex flex-row mt-4">
          <div className="w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Quantity"
              className="pr-2"
              type="text"
              name="quantity"
              value={values?.quantity}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc("quantity", values?.quantity);
              }}
              infoTooltipTitle={TooltipContent("side_face", "quantity")}
              invalid={touched.quantity && errors.quantity}
              helperText={touched.quantity && errors.quantity}
              regex={onlyNumberRegexExp}
            />
          </div>
          <div className="flex justify-between items-center w-6/12 py-[14px] flex-wrap">
            <div className="flex align-baseline mr-auto">
              <label className="font-semibold text-gray-475 truncate mr-1">
                Surface Type
              </label>
              <TooltipV2
                openOnHover={false}
                title={TooltipContent("side_face", "surfaceType")}
              >
                <div className="my-auto">
                  <img
                    className="inline-block"
                    src={FeatureInfoIcon}
                    alt=""
                    height={16}
                    width={16}
                  />
                </div>
              </TooltipV2>
            </div>
            <RadioComponent
              options={SIDE_FACE_SURFACE_TYPE_OPTIONS}
              setValue={(val: any) =>
                handleChangeFunc("surface_type", val, true)
              }
              value={values?.surface_type}
            />
          </div>
        </div>
        {values?.surface_type === "tapered" && (
          <div className="flex flex-row mt-4">
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Tapered Angle"
                className="mr-2"
                type="text"
                name="taper_angle"
                value={values?.taper_angle}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("taper_angle", values?.taper_angle);
                }}
                infoTooltipTitle={TooltipContent("side_face", "taperAngle")}
                invalid={touched.taper_angle && errors.taper_angle}
                helperText={touched.taper_angle && errors.taper_angle}
                regex={decimalRegExp}
              />
            </div>
            <div className="w-6/12">
              <Input
                handleFocus={handleBlur}
                placeholder="Tapered Length"
                className=" !bg-gray-200 rounded-[8px]"
                inputClassName="!bg-gray-200"
                type="text"
                unit={projectInfo?.unit || "mm"}
                name="taper_length"
                disabled={true}
                value={values?.taper_length}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleChangeFunc("taper_length", values?.taper_length);
                }}
                infoTooltipTitle={TooltipContent("side_face", "taperLength")}
                invalid={touched.taper_length && errors.taper_length}
                helperText={touched.taper_length && errors.taper_length}
                regex={decimalRegExp}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <p className="font-semibold text-base text-gray-202">
          Clearance Inputs
        </p>
        <div className="flex flex-row mt-4">
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Vertical Clearance"
              className="w-full mr-2"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="vertical_clearance"
              value={values?.vertical_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "vertical_clearance",
                  values?.vertical_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "side_face",
                "verticalClearance"
              )}
              invalid={touched.vertical_clearance && errors.vertical_clearance}
              helperText={
                touched.vertical_clearance && errors.vertical_clearance
              }
              regex={decimalRegExp}
            />
          </div>
          <div className="flex w-6/12">
            <Input
              handleFocus={handleBlur}
              placeholder="Horizontal Clearance"
              className="w-full"
              type="text"
              unit={projectInfo?.unit || "mm"}
              name="horizontal_clearance"
              value={values?.horizontal_clearance}
              onChange={handleChange}
              onBlur={(e: any) => {
                handleBlur(e);
                handleChangeFunc(
                  "horizontal_clearance",
                  values?.horizontal_clearance
                );
              }}
              infoTooltipTitle={TooltipContent(
                "side_face",
                "horizontalClearance"
              )}
              invalid={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              helperText={
                touched.horizontal_clearance && errors.horizontal_clearance
              }
              regex={decimalRegExp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideFace;
